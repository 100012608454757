import { AxiosRequestConfig } from 'axios';

import { HttpClient } from '@httpClient/index';
import Methods from './methods';
import ActionNames from './actionNames';

class CctvService extends HttpClient<ActionNames> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public constructor(request: any, options?: AxiosRequestConfig) {
    super(request, options);

    this.init();
  }

  private init = (): void => {
    this.requests[ActionNames.CAMERA_INDEX] = this.request({
      method: Methods.CAMERA_INDEX,
    });

    this.requests[ActionNames.STREAM_INDEX] = this.request({
      method: Methods.STREAM_INDEX,
    });
  };
}

export default CctvService;
