import React, { FC } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';

import city from '@core/constants/project';
import * as themes from '@themes/index';

const Theme: FC = ({ children }) => (
  <MuiThemeProvider theme={themes[city]}>{children}</MuiThemeProvider>
);

export default Theme;
