import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import Store from './Store';
import { JrpcResponse } from '@httpClient/jrpc';
import { Loading, endLoading } from './interfaces/Loading';
import {
  Camera,
  CameraInterface,
  CameraFilterProps,
  LoadCameras,
  LoadCamera,
} from '@entities/Opencity/Camera';
import { House } from '@entities/Opencity/House';
import OwnProfileStore from '@stores/OwnProfileStore';
import HouseStore from '@stores/HouseStore';

type CamerasResponse = JrpcResponse<{ items: CameraInterface[]; total: number }>;

interface Relations {
  ownProfileStore: OwnProfileStore;
  houseStore: HouseStore;
}

interface CamerasOnMap {
  cameras: Camera[];
  mapConfig: { center: [number, number]; zoom: number } | null;
}

const ENABLED_PROVIDERS = ['ufanet', 'it_plus'];

class CameraStore extends Store<Relations> implements Loading {
  @observable private _loading: boolean;
  @observable private _cameras: Camera[];
  @observable private _filter: CameraFilterProps;
  @observable private _total: number;
  @observable private _cameraObject: CamerasOnMap;
  @observable private _selectedAddressId: number | null;
  @observable private _selectedCamera: Camera | null;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services, relations: Relations) {
    super(services, relations);
    this._loading = false;
    this._cameras = [];
    this._filter = {};
    this._total = 0;
    this._cameraObject = { cameras: [], mapConfig: null };
    this._selectedAddressId = null;
    this._selectedCamera = null;
  }

  @action public selectAddress = (id: number): void => {
    this._selectedAddressId = id;
  };

  // @action public init = async (): Promise<void> => {
  //   this._loading = true;
  //   const { flats } = this._relations.ownProfileStore;
  //   const houseIds: number[] = [];
  //   const camerasMap = new Map();
  //
  //   flats.forEach(flat => {
  //     if (flat.houseId) houseIds.push(flat.houseId);
  //   });
  //
  //   const addresses: House[] = await this._relations.houseStore.load({
  //     limit: 0,
  //     filter: { id: { $in: houseIds } },
  //   });
  //
  //   for (const address of addresses) {
  //     const { lat, lng } = address;
  //     if (lat && lng) {
  //       const cameras = await this.load({
  //         filter: { latitude: lat, longitude: lng, provider: { $in: ENABLED_PROVIDERS } },
  //       });
  //       if (cameras.length) {
  //         camerasMap.set(address.id, {
  //           cameras: cameras,
  //           mapConfig: { center: [address.lat, address.lng], zoom: 17 },
  //         });
  //       }
  //     }
  //   }
  //
  //   this._camerasMap = camerasMap;
  //
  //   this._endLoading();
  // };

  @action public loadCamerasByHouse = async (houseId: number): Promise<void> => {
    this._loading = true;
    const addresses: House[] = await this._relations.houseStore.load({
      limit: 0,
      filter: { id: { $eq: houseId } },
    });
    const { lat, lng } = addresses[0];

    if (lat && lng) {
      const cameras = await this.load({
        filter: {
          latitude: Number(lat),
          longitude: Number(lng),
          provider: { $in: ENABLED_PROVIDERS },
        },
      });

      if (cameras.length) {
        this._cameraObject = {
          cameras: cameras,
          mapConfig: { center: [lat, lng], zoom: 17 },
        };
      } else {
        this._cameraObject = { cameras: [], mapConfig: null };
      }
    }
  };

  @action public load: LoadCameras = async params => {
    this._loading = true;

    let cameras: Camera[] = [];
    const filter = (params && params.filter) || toJS(this._filter);

    await this._services.cctv.requests
      .cameraIndex({ params: { filter } })
      .then(({ data: { result } }: AxiosResponse<CamerasResponse>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            cameras = items.map(item => new Camera(item));
          }

          this._total = total;
          this._cameras = cameras;
        }
      })
      .finally(this._endLoading);

    return cameras;
  };

  @action public loadCamera: LoadCamera = async params => {
    let cameraData: Camera | null = null;
    if (params && params.id) {
      const id: string = params.id;
      await this._services.cctv.requests
        .streamIndex({ params: { filter: { id } } })
        .then(({ data: { result } }: AxiosResponse<CamerasResponse>) => {
          if (result) {
            const { items } = result;
            cameraData = items[0];
          }
        });
    }
    return cameraData;
  };

  @action public selectCamera = (camera: Camera | null): void => {
    this._selectedCamera = camera;
  };

  @action public cleanUp = (): void => {
    this._cameras = [];
    this._filter = {};
    this._total = 0;
    this._cameraObject = { cameras: [], mapConfig: null };
    this._selectedAddressId = null;
    this._selectedCamera = null;
  };

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get cameras(): Camera[] {
    return toJS(this._cameras);
  }

  // @computed public get camerasByAddress(): CamerasOnMap {
  //   if (this._selectedAddressId && this._camerasMap.get(this._selectedAddressId)) {
  //     return toJS(this._camerasMap.get(this._selectedAddressId)) as CamerasOnMap;
  //   }
  //
  //   return { cameras: [], mapConfig: null };
  // }

  @computed public get selectedCameras(): CamerasOnMap {
    return toJS(this._cameraObject);
  }

  @computed public get selectedAddressId(): number | null {
    return this._selectedAddressId;
  }

  @computed public get selectedCamera(): Camera | null {
    return this._selectedCamera;
  }
}

export default CameraStore;
