import React, { FC, Suspense, ReactElement, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';

import { useYandexMetrika } from '@core/hooks/yandexMetrika';
import { useAuthenticationStore, useSnackbarStore } from './hooks';
import Preloader from 'oc-preloader/lib';
import { APP_ROOT, AUTH_SOCIAL_PAGE, EMAIL_CONFIRM_PAGE } from '@constants/routes';
import config from '@constants/config';
import AuthSocial from '@common/pages/AuthSocial';
import EmailConfirm from '@common/pages/EmailConfirm';

const useStyles = makeStyles(
  createStyles({
    preloader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

const Public = lazy(() => import('./public'));

const Private = lazy(() => import('./private'));

const App: FC = () => {
  const [mountApprove, setMountApprove] = useState(false);
  const { authenticated, refreshByToken } = useAuthenticationStore();
  const { setMessage } = useSnackbarStore();
  const { gAKey } = config;

  useYandexMetrika();

  // useEffect(() => {
  //   setMessage("Если у вас включен VPN, пожалуйста, выключите его", "warning");
  // }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const refresh = url.searchParams.get('refresh');
    const code = url.searchParams.get('code');

    if (code && window.location.href.includes('auth/social')) {
      setMountApprove(false);
    } else if (window.location.href.includes('emailConfirm')) {
      setMountApprove(false);
    } else if (refresh) {
      refreshByToken(refresh).then(() => setMountApprove(true));
    } else setMountApprove(true);
  }, []);

  const classes = useStyles();

  const preloaderElement: ReactElement = <Preloader className={classes.preloader} height="100%" />;

  const renderPrivate = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <Private />
    </Suspense>
  );

  const renderPublic = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <Public />
    </Suspense>
  );

  const renderSocial = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <AuthSocial setMountApprove={setMountApprove} />
    </Suspense>
  );

  const renderEmailConfirm = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <EmailConfirm setMountApprove={setMountApprove} />
    </Suspense>
  );

  return (
    <>
      <Helmet>
        <meta property="og:title" content={config.projectName} />
        <meta property="og:description" content={config.projectDescription} />
        <meta property="og:image" content={`${window.location.origin}/images/share.jpg?v=1`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.origin} />
        <meta name="description" content={config.projectDescription} />
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossOrigin=""
        />
        <script src={`js/clusterer_pie_chart.js`} type="text/javascript"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-176447924-1"></script>
        {gAKey && (
          <script>{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${gAKey}');`}</script>
        )}
      </Helmet>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <BrowserRouter>
          <Switch>
            {!mountApprove && <Route path={AUTH_SOCIAL_PAGE} render={renderSocial} />}
            {!mountApprove && <Route path={EMAIL_CONFIRM_PAGE} render={renderEmailConfirm} />}
            {authenticated && mountApprove && <Route path={APP_ROOT} render={renderPrivate} />}
            {!authenticated && mountApprove && <Route path={APP_ROOT} render={renderPublic} />}
          </Switch>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default App;
