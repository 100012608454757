import { createContext } from 'react';

import Services from '@services/index';
import AuthenticationStore from './AuthenticationStore';
import MapStore from './MapStore';
import AddressStore from './AddressStore';
import ClassifierStore from './ClassifierStore';
import OrderStore from './OrderStore';
import RatingStore from './RatingStore';
import FlatAccountStore from './FlatAccountStore';
import StatisticsStore from './StatisticsStore';
import ShutdownStore from './ShutdownStore';
import OwnProfileStore from './OwnProfileStore';
import FlatStore from './FlatStore';
import ProfileStore from './ProfileStore';
import SnackbarStore from './SnackbarStore';
import CommentStore from './CommentStore';
import StreetStore from './StreetStore';
import HouseStore from './HouseStore';
import ResourseStore from './ResourceStore';
import PasswordStore from './PasswordStore';
import OperationStore from './OperationStore';
import TransitionStore from './TransitionStore';
import OrderDocumentStore from './OrderDocumentStore';
import OrganizationStore from './OrganizationStore';
import ShutdownReasonStore from './ShutdownReasonStore';
import ShutdownSenderStore from './ShutdownSenderStore';
import ShutdownTypeStore from './ShutdownTypeStore';
import UserStore from './UserStore';
import FeedbackStore from './FeedbackStore';
import ClassifierTagStore from './ClassifierTagStore';
import ClassifierTrackerStore from './ClassifierTrackerStore';
import ClassifierTypeStore from './ClassifierTypeStore';
import ClassifierCategoryStore from './ClassifierCategoryStore';
import SignStore from './SignStore';
import ConfigurationStore from './ConfigurationStore';
import CameraStore from './CameraStore';
import BillingOrganizationStore from './BillingOrganizationStore';
import AccountStore from './AcccountStore';
import MerchantGroupStore from './MerchantGroupStore';
import MerchantStore from './MerchantStore';
import ServiceStore from './ServiceStore';
import PaymentStore from './PaymentStore';
import AccrualStore from '@stores/AccrualStore';
import CounterStore from './CounterStore';
import ReportStore from './ReportStore';
import AuthProviderStore from './AuthProviderStore';
import MeteringDeviceStore from './MeteringDeviceStore';
import MeteringDeviceValueStore from './MeteringDeviceValueStore';
import FastPaymentStore from './FastPaymentStore';
import TaskStore from './TaskStore';
import ChangeEmailStore from './ChangeEmailStore';
import DistrictStore from './DistrictStore';
import PreferenceStore from './PreferenceStore';
import { AvatarStore } from './AvatarStore';
import NotificationStore from './NotificationStore';

const services: Services = Services.getServices();
const authenticationStore: AuthenticationStore = new AuthenticationStore(services);
const configurationStore = new ConfigurationStore();
const preferenceStore = new PreferenceStore(services);
const snackbarStore: SnackbarStore = new SnackbarStore();
const mapStore: MapStore = new MapStore(services);
const classifierStore: ClassifierStore = new ClassifierStore(services);
const ratingStore: RatingStore = new RatingStore(services);
const flatAccountStore: FlatAccountStore = new FlatAccountStore(services);
const statisticsStore: StatisticsStore = new StatisticsStore(services);
const signStore = new SignStore(services, authenticationStore, snackbarStore);
const flatStore: FlatStore = new FlatStore(services);
const profileStore: ProfileStore = new ProfileStore(services);
const userStore: UserStore = new UserStore(services);
const accountStore = new AccountStore(services);
const taskStore = new TaskStore(services);
const ownProfileStore: OwnProfileStore = new OwnProfileStore(services, {
  authenticationStore,
  profileStore,
  snackbarStore,
  userStore,
  accountStore,
  preferenceStore,
});
const commentStore: CommentStore = new CommentStore(services, { ownProfileStore });
const shutdownStore: ShutdownStore = new ShutdownStore(services, {
  ownProfileStore,
  snackbarStore,
});
const transitionStore: TransitionStore = new TransitionStore(services, { ownProfileStore });
const addressStore: AddressStore = new AddressStore(services, { flatStore });
const streetStore: StreetStore = new StreetStore(services);
const districtStore: DistrictStore = new DistrictStore(services);
const houseStore: HouseStore = new HouseStore(services);
const resourceStore: ResourseStore = new ResourseStore(services);
const passwordStore: PasswordStore = new PasswordStore(services, { snackbarStore });
const operationStore: OperationStore = new OperationStore(services, { authenticationStore });
const orderDocumentStore: OrderDocumentStore = new OrderDocumentStore(services);
const orderStore: OrderStore = new OrderStore(services, {
  commentStore,
  orderDocumentStore,
  ownProfileStore,
  snackbarStore,
  configurationStore,
});
const organizationStore: OrganizationStore = new OrganizationStore(services);
const shutdownReasonStore: ShutdownReasonStore = new ShutdownReasonStore(services);
const shutdownSenderStore: ShutdownSenderStore = new ShutdownSenderStore(services);
const shutdownTypeStore: ShutdownTypeStore = new ShutdownTypeStore(services);
const feedbackStore = new FeedbackStore(services, { snackbarStore });
const classifierTrackerStore = new ClassifierTrackerStore(services);
const classifierCategoryStore = new ClassifierCategoryStore(services);
const classifierTagStore = new ClassifierTagStore(services);
const classifierTypeStore = new ClassifierTypeStore(services);
const billingOrganizationStore = new BillingOrganizationStore(services);
const merchantGroupStore = new MerchantGroupStore(services);
const merchantStore = new MerchantStore(services);
const serviceStore = new ServiceStore(services);
const paymentStore = new PaymentStore(services);
const cameraStore = new CameraStore(services, { ownProfileStore, houseStore });
const counterStore = new CounterStore(services, { snackbarStore });
const accrualStore = new AccrualStore(services);
const authProviderStore = new AuthProviderStore(services);
const reportStore = new ReportStore(services, { snackbarStore });
const meteringDeviceValueStore = new MeteringDeviceValueStore(services, { snackbarStore });
const fastPaymentStore = new FastPaymentStore(services);
const changeEmailStore = new ChangeEmailStore(services);
const notificationStore = new NotificationStore(services, { snackbarStore });
const avatarStore = new AvatarStore(services);

const meteringDeviceStore = new MeteringDeviceStore(services, {
  meteringDeviceValueStore,
  snackbarStore,
});

export const RootContext = createContext({
  avatarStore,
  addressStore,
  authenticationStore,
  classifierStore,
  commentStore,
  flatAccountStore,
  flatStore,
  houseStore,
  mapStore,
  operationStore,
  orderDocumentStore,
  orderStore,
  organizationStore,
  ownProfileStore,
  passwordStore,
  profileStore,
  ratingStore,
  resourceStore,
  shutdownReasonStore,
  shutdownSenderStore,
  shutdownStore,
  shutdownTypeStore,
  signStore,
  snackbarStore,
  statisticsStore,
  streetStore,
  transitionStore,
  userStore,
  feedbackStore,
  classifierTrackerStore,
  classifierCategoryStore,
  classifierTagStore,
  classifierTypeStore,
  configurationStore,
  billingOrganizationStore,
  accountStore,
  merchantGroupStore,
  merchantStore,
  serviceStore,
  paymentStore,
  cameraStore,
  accrualStore,
  counterStore,
  reportStore,
  authProviderStore,
  meteringDeviceStore,
  meteringDeviceValueStore,
  fastPaymentStore,
  taskStore,
  changeEmailStore,
  districtStore,
  preferenceStore,
  notificationStore,
});
