import { AxiosResponse } from 'axios';
import { observable, computed, action, toJS } from 'mobx';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import { Feedback, FeedbackCreationProps, FeedbackCreate } from '@core/entities/Opencity/Feedback';
import SnackbarStore from './SnackbarStore';
import Store from './Store';
import { Entity } from './interfaces/Entity';
import { Loading, endLoading } from './interfaces/Loading';

type FeedbackCreateResponse = AxiosResponse<JrpcResponse<number>>;

interface FeedbackStoreRelations {
  snackbarStore: SnackbarStore;
}

class FeedbackStore extends Store<FeedbackStoreRelations>
  implements Loading, Entity<Feedback, {}, number, FeedbackCreationProps> {
  @observable private _feedbacks: Feedback[];
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services, relations: FeedbackStoreRelations) {
    super(services, relations);

    this._feedbacks = [];
    this._loading = false;
  }

  @action public create: FeedbackCreate = async (props, options) => {
    let feedbackId: number | null = null;

    await this._services.opencity.requests
      .feedbackCreate({ params: { ...props } })
      .then(({ data: { result } }: FeedbackCreateResponse) => {
        if (typeof result === 'number') {
          feedbackId = result;

          if (options?.notification) {
            this._relations.snackbarStore.setMessage('Обращение отправлено', 'success');
          }
        }
      })
      .finally(this._endLoading);

    return feedbackId;
  };

  @computed public get list(): Feedback[] {
    return toJS(this._feedbacks);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default FeedbackStore;
