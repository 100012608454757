import { observable, action, computed, toJS } from 'mobx';
import { AxiosResponse } from 'axios';
import { mergeDeepRight } from 'ramda';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  CommentInterface,
  Comment,
  CommentKeys,
  CommentFilterProps,
  AddCommentFilter,
  ClearCommentFilter,
  LoadComment,
  AddComment,
} from '@core/entities/Opencity/Comment';
import OwnProfileStore from './OwnProfileStore';
import Store from './Store';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Entity } from './interfaces/Entity';
import { Filter } from './interfaces/Filter';
import { Loading, endLoading } from './interfaces/Loading';
import { TaskState, TaskStateInterface } from '@core/entities/Opencity/TaskState';

interface Relations {
  ownProfileStore: OwnProfileStore;
}

type IssueCommentIndexResponse = JrpcResponse<{ items: CommentInterface[]; total: number }>;
type TaskStateIndexResponse = JrpcResponse<{ items: TaskStateInterface[]; total: number }>;
type TaskCommentCreate = JrpcResponse<CommentInterface[]>;

class CommentStore extends Store<Relations>
  implements
    Pagination,
    Loading,
    Filter<CommentFilterProps, CommentKeys>,
    Entity<Comment, { filter?: CommentFilterProps }> {
  @observable private _comments: Comment[];
  @observable private _filter: CommentFilterProps;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services, relations: Relations) {
    super(services, relations);

    this._comments = [];
    this._filter = {};
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._loading = false;
  }

  @action public load: LoadComment = async params => {
    let comments: Comment[] = [];

    this._loading = true;

    const filter = (params && params.filter) || toJS(this._filter);
    const limit = params && typeof params.limit ? params.limit : this._limit;

    this._filter = filter;

    await this._services.opencity.requests
      .issueCommentIndex({
        params: {
          filter,
          limit,
          offset: this.offset,
        },
      })
      .then(({ data: { result } }: AxiosResponse<IssueCommentIndexResponse>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            comments = items.map(value => new Comment(value));

            this._comments = comments;
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return comments;
  };

  @action public loadTaskState = async (params: any): Promise<Comment[]> => {
    let comments: Comment[] = [];

    this._loading = true;

    const filter = (params && params.filter) || toJS(this._filter);
    const limit = params && typeof params.limit ? params.limit : this._limit;

    this._filter = filter;

    await this._services.opencity.requests
      .taskStateIndex({
        params: {
          filter,
          limit,
          offset: this.offset,
        },
      })
      .then(({ data: { result } }: AxiosResponse<IssueCommentIndexResponse>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            comments = items.map(value => new Comment(value));

            this._comments = comments;
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return comments;
  };

  @action public getTaskState = async (params: any): Promise<TaskState[]> => {
    let comments: TaskState[] = [];

    // this._loading = true;

    const filter = (params && params.filter) || toJS(this._filter);
    const limit = params && typeof params.limit ? params.limit : this._limit;

    // this._filter = filter;

    await this._services.opencity.requests
      .taskStateIndex({
        params: {
          filter,
          limit,
          offset: this.offset,
        },
      })
      .then(({ data: { result } }: AxiosResponse<TaskStateIndexResponse>) => {
        if (result) {
          const { items } = result;

          if (Array.isArray(items)) {
            comments = items.map(value => new TaskState(value));

            // this._comments = comments;
          }

          // if (typeof total === 'number') {
          //   this._total = total;
          // }
        }
      })
      .finally(this._endLoading);

    return comments;
  };

  @action public addComment: AddComment = async data => {
    let addCommentResult = false;

    this._loading = true;

    await this._services.opencity.requests
      .taskCommentCreate({
        params: {
          data,
          ...(this._relations.ownProfileStore.user && {
            userId: this._relations.ownProfileStore.user.id,
          }),
        },
      })
      .then(({ data: { result } }: AxiosResponse<TaskCommentCreate>) => {
        if (result) {
          addCommentResult = true;
        }
      })
      .finally(this._endLoading);

    return addCommentResult;
  };

  @action public addFilter: AddCommentFilter = filter => {
    this._filter = mergeDeepRight(this._filter, filter);
  };

  @action public clearFilter: ClearCommentFilter = () => {
    this._filter = {};
  };

  @action public setLimit: SetLimit = limit => {
    this._limit = limit;
  };

  @action public setOffset: SetOffset = offset => {
    this._offset = offset;
  };

  @action public cleanUp = (): void => {
    this._comments = [];
    this._filter = {};
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._loading = false;
  };

  @computed public get list(): Comment[] {
    return toJS(this._comments);
  }

  @computed public get filter(): CommentFilterProps {
    return toJS(this._filter);
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default CommentStore;
