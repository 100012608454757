import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import { Profile, ProfileInterface } from '@core/entities/Opencity/Profile';
import { Flat, FlatInterface } from '@core/entities/Opencity/Flat';
import { FlatAccount, FlatAccountInterface } from '@core/entities/Opencity/FlatAccount';
import { User, UserInterfacee } from '@core/entities/Opencity/User';
import {
  User as BillingUser,
  UserInterface as BillingUserInterface,
  CreateUser,
  UpdateProps,
} from '@core/entities/Billing/User';
import { Loading, endLoading } from './interfaces/Loading';
import Store from './Store';
import AuthenticationStore from './AuthenticationStore';
import ProfileStore from './ProfileStore';
import SnackbarStore from './SnackbarStore';
import UserStore from './UserStore';
import { isBilling } from '@constants/project';
import AccountStore from './AcccountStore';
import PreferenceStore from './PreferenceStore';
import { DEFAULT_USER_PREFERENCE, UserPreference } from '@core/entities/Opencity/Preference';

type ProfileIndexResponse = JrpcResponse<{ items: ProfileInterface[] }>;
type UserIndexResponse = JrpcResponse<{ items: UserInterfacee[] }>;
type UserCreateResponse = JrpcResponse<BillingUser>;
type UserUpdateResponse = JrpcResponse<BillingUser>;
type BillingUserIndexResponse = JrpcResponse<{ items: BillingUserInterface[] }>;
type GisFlatIndexResponse = JrpcResponse<{ items: FlatInterface[] }>;
type FlatAccountIndexResponse = JrpcResponse<{ items: FlatAccountInterface[] }>;
type ProfileFlatAccountCreateResponse = JrpcResponse<number, { code: number }>;
type ProfileAccountDeleteResponse = JrpcResponse<number>;

interface Relations {
  authenticationStore: AuthenticationStore;
  profileStore: ProfileStore;
  snackbarStore: SnackbarStore;
  userStore: UserStore;
  accountStore: AccountStore;
  preferenceStore: PreferenceStore;
}

class OwnProfileStore extends Store<Relations> implements Loading {
  @observable private _profile?: Profile;
  @observable private _user?: User;
  @observable private _flats: Flat[];
  @observable private _loading: boolean;
  @observable private _billingUsers: BillingUser[];
  @observable private _selectedBillingUser: BillingUser | undefined;
  @observable private _accountDataLoading: boolean;
  @observable private _selectedFlat: Flat | undefined;
  @observable private _userPreference: UserPreference;
  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services, relations: Relations) {
    super(services, relations);

    this._flats = [];
    this._loading = false;
    this._accountDataLoading = false;
    this._selectedFlat = undefined;
    this._billingUsers = [];
    this._selectedBillingUser = undefined;
    this._userPreference = DEFAULT_USER_PREFERENCE;
  }

  @action public load = async (): Promise<void> => {
    this._loading = true;
    this._accountDataLoading = true;

    const user: User | undefined = await this.getUser();
    const profile: Profile | undefined = await this.getProfile(user);
    const preferences = await this._relations.preferenceStore.getUserPreference();
    this._userPreference = preferences;

    if (isBilling) {
      await this.loadUserAccount();
    }
    if (!isBilling) {
      this._flats = await this.loadFlat(profile);
      const { selectedFlatId } = preferences;
      const selectedFlat = this._flats.find(item => item.id === selectedFlatId);
      this.selectFlat(selectedFlat || this._flats[0]);
    }
    this._endLoading();
    this._accountDataLoading = false;
  };

  @action public selectFlat = (flat: Flat | undefined): void => {
    this._selectedFlat = flat;
    if (flat) {
      this._relations.preferenceStore.updateUserPreference({
        data: {
          ...this._userPreference,
          selectedFlatId: flat.id,
        },
      });
    }
  };

  @action public loadUserAccount = async (): Promise<void> => {
    this._loading = true;
    this._accountDataLoading = true;

    await this.getUserAccount();

    this._endLoading();
    this._accountDataLoading = false;
  };

  @action public update = async (values: Partial<Profile>): Promise<void> => {
    this._loading = true;

    const phoneNumber: string | undefined = values.phone?.replace(/\D/g, '');

    const profile: Profile | undefined = await this._relations.profileStore.update({
      payload: {
        ...values,
        phone: phoneNumber && phoneNumber.length > 1 ? phoneNumber : '',
      },
    });

    this._profile = profile;

    this._endLoading();
  };

  @action public changeUserEmailConfirm = (): void => {
    if (this._user) {
      this._user = { ...this._user, isEmailConfirmed: 0 };
    }
  };

  @action public delete = async (): Promise<boolean> => {
    this._loading = true;

    let isDeleted = false;

    await this._relations.userStore
      .delete({ filter: { id: { $eq: this.user?.id } } })
      .then(value => (isDeleted = value))
      .finally(this._endLoading);

    return isDeleted;
  };

  @action public cleanUp = (): void => {
    this._loading = false;
    this._user = undefined;
    this._profile = undefined;
    this._flats = [];
    // this._billingUser = undefined;
  };

  @action public addAddress = async (
    flatId: number,
    flatAccountNumber: string,
    withoutUpdate?: boolean,
  ): Promise<{ error: string }> => {
    let resultError = '';

    await this._services.opencity.requests
      .profileAccountCreate({
        params: {
          data: { flatId, profileId: this._profile?.id, personalAccountNumber: flatAccountNumber },
        },
      })
      .then(
        async ({
          data: { result, error },
        }: AxiosResponse<ProfileFlatAccountCreateResponse>): Promise<void> => {
          if (result) {
            this._loading = true;

            if (withoutUpdate) {
              this._relations.snackbarStore.setMessage('Адрес успешно добавлен', 'success');
            } else {
              this.loadFlat(this._profile)
                .then((flats: Flat[]): void => {
                  this._flats = flats;
                })
                .finally((): void => {
                  this._endLoading();

                  this._relations.snackbarStore.setMessage('Адрес успешно добавлен', 'success');
                });
            }
          }

          if (error) {
            switch (error.code) {
              case 111004: {
                resultError = 'Данный адрес уже добавлен';

                break;
              }

              case 160000: {
                resultError = 'Данный адрес уже добавлен';

                break;
              }

              case 120000: {
                resultError = 'Лицевой счёт не найден';

                break;
              }

              default: {
                resultError = '';
              }
            }
          }
        },
      );

    return { error: resultError };
  };

  @action public removeAddress = (flatAccountId: number): void => {
    this._loading = true;

    this._services.opencity.requests
      .profileAccountDelete({
        params: { filter: { profileId: this._profile?.id, personalAccountId: flatAccountId } },
      })
      .then(({ data: { result } }: AxiosResponse<ProfileAccountDeleteResponse>): void => {
        if (result) {
          this._flats = this._flats.filter(flat => flat.flatAccount?.id !== flatAccountId);
          this.selectFlat(this._flats[0]);
          this._relations.snackbarStore.setMessage('Адрес успешно удален', 'success');
        }
      })
      .finally(this._endLoading);
  };

  @action private getUser = async (): Promise<User | undefined> => {
    let user: User | undefined;

    if (this._relations.authenticationStore.tokenPayload) {
      await this._services.opencity.requests
        .userIndex({
          params: {
            filter: { authId: this._relations.authenticationStore.tokenPayload.sub },
          },
        })
        .then(({ data: { result } }: AxiosResponse<UserIndexResponse>): void => {
          if (result && result.items.length === 1) {
            user = new User(result.items[0]);

            this._user = user;
          }
        });
    }

    return user;
  };

  @action public selectBillingUser = (user: BillingUser): void => {
    this._selectedBillingUser = user;
    this._relations.preferenceStore.updateUserPreference({
      data: {
        ...this._userPreference,
        selectedUserId: user.id,
      },
    });
  };

  @action private getUserAccount = async (): Promise<BillingUser[]> => {
    let users: BillingUser[] = [];

    if (this._relations.authenticationStore.tokenPayload) {
      await this._services.billing.requests
        .userAccountIndex({
          params: {
            filter: { authId: this._relations.authenticationStore.tokenPayload.sub },
          },
        })
        .then(({ data: { result } }: AxiosResponse<BillingUserIndexResponse>): void => {
          if (result && result.items) {
            users = result.items;
          }
        });

      for (const i in users) {
        const account = await this._relations.accountStore.load({
          filter: {
            organizationId: { $eq: Number(users[i].organizationId) },
            accountNumber: { $eq: users[i].number },
          },
        });
        users[i].account = account[0];
      }
      this.selectBillingUser(users[0]);

      this._billingUsers = users;
    }

    return users;
  };

  @action public createBillingUser: CreateUser = async ({
    number,
    organizationId,
    organizationName,
    providerId,
  }) => {
    this._loading = true;
    let creationResult = false;

    await this._services.billing.requests
      .userAccountCreate({
        params: {
          data: {
            ...(this._relations.authenticationStore.tokenPayload && {
              authId: this._relations.authenticationStore.tokenPayload.sub,
            }),
            organizationName,
            organizationId,
            number,
            providerId,
          },
        },
      })
      .then(({ data: { result, error } }: AxiosResponse<UserCreateResponse>) => {
        if (result) {
          creationResult = true;

          this._relations.snackbarStore.setMessage('Лицевой счёт прикреплен', 'success');
        }

        if (error && error.code === 900000) {
          this._relations.snackbarStore.setMessage('Лицевой счёт не найден', 'error');
        }
      })
      .finally(this._endLoading);

    return creationResult;
  };

  @action public updateBillingUser: UpdateProps = async params => {
    this._loading = true;
    let updationResult = false;

    if (this._relations.authenticationStore.tokenPayload) {
      const { accountNumber, sendToAddress, sendToEmail } = params;
      await this._services.billing.requests
        .userAccountUpdate({
          params: {
            filter: {
              authId: this._relations.authenticationStore.tokenPayload.sub,
              number: accountNumber,
            },
            data: {
              sendToAddress: sendToAddress,
              sendToEmail: sendToEmail,
            },
          },
        })
        .then(({ data: { result } }: AxiosResponse<UserUpdateResponse>) => {
          if (result) {
            updationResult = true;

            this._billingUsers = this._billingUsers.map(user => {
              const {
                accountNumber,
                sendToAddress = user.sendToAddress,
                sendToEmail = user.sendToEmail,
              } = params;

              if (user.number === accountNumber) {
                return {
                  ...user,
                  sendToAddress,
                  sendToEmail,
                };
              }
              return user;
            });

            if (this._selectedBillingUser?.number === accountNumber) {
              this.selectBillingUser({ ...this._selectedBillingUser, sendToAddress, sendToEmail });
            }
          }
        })
        .finally(this._endLoading);
    }

    return updationResult;
  };

  @action private getProfile = async (user: User | undefined): Promise<Profile | undefined> => {
    let profile: Profile | undefined;

    if (user) {
      await this._services.opencity.requests
        .profileIndex({
          params: {
            filter: { id: user.profileId },
          },
        })
        .then(({ data: { result } }: AxiosResponse<ProfileIndexResponse>): void => {
          if (result && result.items.length === 1) {
            profile = new Profile(result.items[0]);

            this._profile = profile;
          }
        });
    }

    return profile;
  };

  @action private loadFlat = async (profile: Profile | undefined): Promise<Flat[]> => {
    let flats: Flat[] = [];

    if (profile) {
      await this._services.opencity.requests
        .gisFlatIndex({
          params: { filter: { citizenId: { $eq: profile.id } }, limit: 0 },
        })
        .then(({ data: { result } }: AxiosResponse<GisFlatIndexResponse>): void => {
          if (result && Array.isArray(result.items)) {
            if (Array.isArray(result.items)) {
              flats = result.items.map((value: FlatInterface): Flat => new Flat(value));
            }
          }
        });

      await this._services.opencity.requests
        .flatAccountIndex({
          params: { filter: { citizenId: { $eq: profile.id } }, limit: 0 },
        })
        .then(({ data: { result } }: AxiosResponse<FlatAccountIndexResponse>): void => {
          if (result && Array.isArray(result.items) && flats) {
            flats = flats.map(
              (flat: Flat): Flat => {
                const flatAccount: FlatAccountInterface | undefined = result.items.find(
                  (value: FlatAccountInterface): boolean => value.flatId === flat.id,
                );

                if (flatAccount) {
                  return { ...flat, flatAccount: new FlatAccount(flatAccount) };
                }

                return flat;
              },
            );
          }
        });
    }
    this._flats = flats;
    return flats;
  };

  @computed public get profile(): Profile | undefined {
    return toJS<Profile | undefined>(this._profile);
  }

  @computed public get user(): User | undefined {
    return toJS<User | undefined>(this._user);
  }

  @computed public get billingUsers(): BillingUser[] {
    return toJS<BillingUser[]>(this._billingUsers);
  }

  @computed public get flats(): Flat[] {
    return toJS<Flat[]>(this._flats);
  }

  @computed public get houseIds(): number[] {
    return this._flats
      .map(item => item.houseId)
      .filter(item => typeof item === 'number') as number[];
  }

  @computed public get selectedFlat(): Flat | undefined {
    return toJS(this._selectedFlat);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get accountDataLoading(): boolean {
    return this._accountDataLoading;
  }
  @computed public get selectedBillingUser(): BillingUser | undefined {
    return toJS(this._selectedBillingUser);
  }
}

export default OwnProfileStore;
