import { AxiosResponse } from 'axios';
import { observable, computed, action, toJS } from 'mobx';
import { mergeRight } from 'ramda';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  Profile,
  ProfileInterface,
  ProfileKeys,
  ProfileFilterProps,
  LoadProfile,
  CreateProfile,
  UpdateProfile,
  GetProfile,
  CreateFlatAccountOwner,
} from '@core/entities/Opencity/Profile';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Pagination } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';
import { Entity } from './interfaces/Entity';

type ProfileIndexResponse = JrpcResponse<
  { items: ProfileInterface[]; total: number },
  {},
  { filter: ProfileFilterProps }
>;

type ProfileCreateResponse = JrpcResponse<Profile>;

class ProfileStore extends Store
  implements
    Loading,
    Pagination,
    Filter<ProfileFilterProps, ProfileKeys>,
    Entity<Profile, { filter?: ProfileFilterProps }, number, Profile, ProfileFilterProps> {
  @observable private _profiles: Profile[];
  @observable private _filter: ProfileFilterProps;
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services) {
    super(services);

    this._profiles = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  }

  @action public create: CreateProfile = async values => {
    let profile: Profile | null = null;

    await this._services.opencity.requests
      .profileCreate({
        params: { data: values },
      })
      .then(({ data: { result } }: AxiosResponse<ProfileCreateResponse>) => {
        if (result) {
          profile = result;
        }
      });

    return profile;
  };

  @action public update: UpdateProfile = async ({ payload, entity }) => {
    if (payload) {
      const values: Partial<Profile> = entity ? mergeRight(payload, entity) : payload;
      const params: ProfileInterface = {
        email: values.email,
        id: values.id || 0,
        lastname: values.lastName,
        name: values.name,
        patronymic: values.patronymic,
        phone: values.phone,
      };

      await this._services.opencity.requests.profileUpdate({
        params,
      });

      return new Profile(params);
    }
  };

  @action public createFlatAccountOwner: CreateFlatAccountOwner = async ({
    profileId,
    personalAccountId,
  }) => {
    let creationResult = false;

    await this._services.opencity.requests
      .flatAccountOwnerCreate({
        params: { data: { profileId, personalAccountId } },
      })
      .then(
        ({
          data: { result },
        }: AxiosResponse<{ result: { profileId: number; personalAccountId: number } }>) => {
          if (result) {
            creationResult = Boolean(result);
          }
        },
      );

    return creationResult;
  };

  @action public updateFlatAccountOwner: CreateFlatAccountOwner = async ({
    profileId,
    personalAccountId,
  }) => {
    let creationResult = false;

    await this._services.opencity.requests
      .flatAccountOwnerUpdate({
        params: { profileId, personalAccountId },
      })
      .then(
        ({
          data: { result },
        }: AxiosResponse<{ result: { profileId: number; personalAccountId: number } }>) => {
          if (result) {
            creationResult = Boolean(result);
          }
        },
      );

    return creationResult;
  };

  @action public load: LoadProfile = async params => {
    this._loading = true;

    let profiles: Profile[] = [];

    const { _filter, _limit, _offset } = this;

    const filter = (params && params.filter) || toJS(_filter);
    const limit = (params && params.limit) || _limit;
    const offset = (params && params.offset) || _offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.opencity.requests
      .profileIndex({
        params: { filter, limit, offset },
      })
      .then(({ data: { result } }: AxiosResponse<ProfileIndexResponse>) => {
        if (result && Array.isArray(result.items)) {
          profiles = result.items.map(value => new Profile(value));

          this._profiles = profiles;
          this._total = result.total;
        }
      })
      .finally(this._endLoading);

    return profiles;
  };

  @action public get: GetProfile = async filter =>
    this.list.find(
      (value: Profile): boolean =>
        value.type === filter[ProfileKeys.TYPE] &&
        value.personalAccountId === filter[ProfileKeys.PERSONAL_ACCOUNT_ID],
    );

  @action public cleanUp = (): void => {
    this._profiles = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  };

  @computed public get list(): Profile[] {
    return toJS(this._profiles);
  }

  @computed public get filter(): ProfileFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default ProfileStore;
