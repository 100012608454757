import { AxiosResponse } from 'axios';
import { action, observable, computed } from 'mobx';
import { isEmpty } from 'ramda';

import Services from '@services/index';
import Store from './Store';
import {
  UserPreference,
  UpdateUserPreference,
  DEFAULT_USER_PREFERENCE,
} from '@entities/Opencity/Preference';
import { JrpcResponse } from '@httpClient/jrpc';

type UserPreferenceResponse = JrpcResponse<UserPreference>;

class PreferenceStore extends Store {
  @observable private _userPreference: UserPreference;

  constructor(services: Services) {
    super(services);
    this._userPreference = DEFAULT_USER_PREFERENCE;
  }

  @action public getUserPreference = async (): Promise<UserPreference> => {
    let userPreference = this._userPreference;

    await this._services.opencity.requests
      .userPreferenceIndex()
      .then(({ data: { result } }: AxiosResponse<UserPreferenceResponse>) => {
        if (result) {
          if (isEmpty(result)) {
            userPreference = DEFAULT_USER_PREFERENCE;
          } else {
            userPreference = result;
          }
        }
      });

    this._userPreference = userPreference;

    return userPreference;
  };

  @action public updateUserPreference: UpdateUserPreference = async params => {
    this._userPreference = params.data;

    this._services.opencity.requests.userPreferenceUpdate({
      params: { data: { ...params.data } },
    });

    return this._userPreference;
  };

  @computed public get userPreference(): UserPreference {
    return this._userPreference;
  }
}

export default PreferenceStore;
