import { v4 as uuidV4 } from 'uuid';

import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';

export interface AccountInterface {
  address?: string | null;
  number?: string | null;
  payer?: string | null;
  providerId?: number | null;
  balanceByService?: number | null;
  penalty?: number | null;
  balance?: number | null;
  balanceByNds?: number | null;
  balanceDate?: string | null;
  canLoadCounter?: boolean | null;
  lastPayedDate: number | null;
  currentMonthPayed: number;
  active: number;
  invoiceFileUrl?: string | null;
  transferringPossibility: boolean;
}

export enum AccountStatus {
  CLOSED,
  ACTIVE,
}

export class Account {
  public id: string;
  public address?: string | null;
  public number?: string | null;
  public payer?: string | null;
  public providerId?: number | null;
  public balanceByService?: number | null;
  public penalty?: number | null;
  public balance?: number | null;
  public balanceByNds?: number | null;
  public balanceDate?: string | null;
  public canLoadCounter?: boolean | null;
  public lastPayedDate: number | null;
  public currentMonthPayed: number;
  public active: number;
  public invoiceFileUrl?: string | null;
  public transferringPossibility: boolean;

  public constructor(value: AccountInterface) {
    this.id = uuidV4();
    this.address = value.address;
    this.number = value.number;
    this.payer = value.payer;
    this.providerId = value.providerId;
    this.balance = value.balance;
    this.balanceByService = value.balanceByService;
    this.penalty = value.penalty;
    this.balanceByService = value.balance;
    this.balanceByNds = value.balanceByNds;
    this.balanceDate = value.balanceDate;
    this.canLoadCounter = value.canLoadCounter;
    this.lastPayedDate = value.lastPayedDate;
    this.currentMonthPayed = value.currentMonthPayed;
    this.active = value.active;
    this.invoiceFileUrl = value.invoiceFileUrl;
    this.transferringPossibility = value.transferringPossibility;
  }
}

export enum AccountKeys {
  ID = 'id',
  ORGANIZATION_ID = 'organizationId',
  ACCOUNT_NUMBER = 'accountNumber',
}

export interface AccountFilterProps {
  [AccountKeys.ID]?: FilterOperators<number>;
  [AccountKeys.ORGANIZATION_ID]?: FilterOperators<number>;
  [AccountKeys.ACCOUNT_NUMBER]?: FilterOperators<string>;
}

export type LoadAccount = Load<
  { filter?: AccountFilterProps; limit?: number; offset?: number },
  Account[]
>;

export type AccountTemplate = {
  executor: string;
  executorId: string;
  flatAccountNumber: string;
};
