import { FilterOperators } from '@stores/interfaces/Filter';
import { Load, Get } from '@stores/interfaces/Entity';

export interface OrganizationInterface {
  id: number;
  name?: string | null;
  fullName?: string | null;
  ercCode?: number | null;
  phone?: string | null;
  emergencyPhone?: string | null;
  role?: OrganizationRoles | null;
  address?: string | null;
  factAddress?: string | null;
  email?: string | null;
  manager?: string | null;
  workTime?: string | null;
  inn?: string | null;
}

export class Organization {
  public id: number;
  public name?: string | null;
  public fullName?: string | null;
  public ercCode?: number | null;
  public phone?: string | null;
  public emergencyPhone?: string | null;
  public role?: OrganizationRoles | null;
  public address?: string | null;
  public actualAddress?: string | null;
  public email?: string | null;
  public manager?: string | null;
  public workTime?: string | null;
  public inn?: string | null;

  public constructor(value: OrganizationInterface) {
    this.id = value.id;
    this.name = value.name;
    this.fullName = value.fullName;
    this.ercCode = value.ercCode;
    this.phone = value.phone?.replace(/^8/g, '+7') || value.phone;
    this.emergencyPhone = value.emergencyPhone;
    this.role = value.role;
    this.address = value.address;
    this.actualAddress = value.factAddress;
    this.email = value.email;
    this.manager = value.manager;
    this.workTime = value.workTime;
    this.inn = value.inn;
  }
}

export enum OrganizationRoles {
  provider = 'provider',
  control = 'control',
}

export enum OrganizationKeys {
  ROLE = 'role',
  CONTROL_HOUSE_ID = 'controlHouseId',
  ID = 'id',
  CONTROL_ID = 'extendedControlId',
  FULL_NAME = 'fullName',
  NAME = 'name',
  TRACKER_ID = 'trackerId',
  PROVIDER_TRACKER_ID = 'providerTrackerId',
  DISTRICT_ID = 'districtId',
}

export interface OrganizationFilterProps {
  [OrganizationKeys.ROLE]?: FilterOperators<OrganizationRoles>;
  [OrganizationKeys.CONTROL_HOUSE_ID]?: FilterOperators<number>;
  [OrganizationKeys.ID]?: FilterOperators<number>;
  [OrganizationKeys.CONTROL_ID]?: FilterOperators<number>;
  [OrganizationKeys.FULL_NAME]?: FilterOperators<string>;
  [OrganizationKeys.NAME]?: FilterOperators<string>;
  [OrganizationKeys.TRACKER_ID]?: FilterOperators<number>;
  [OrganizationKeys.PROVIDER_TRACKER_ID]?: FilterOperators<number>;
  [OrganizationKeys.DISTRICT_ID]?: FilterOperators<number>;
}

export type LoadOrganization = Load<
  { filter?: OrganizationFilterProps; limit?: number },
  Organization[]
>;

export type GetOrganization = Get<Organization, OrganizationFilterProps>;
