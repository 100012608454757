import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';

export interface ServiceInterface {
  id: number;
  merchantId?: string;
  balance: number;
  name?: string | null;
  description?: string | null;
  counterExist?: boolean | null;
}

export class Service {
  public id: number;
  public merchantId?: string;
  public balance: number;
  public name?: string | null;
  public description?: string | null;
  public counterExist?: boolean | null;

  public constructor(value: ServiceInterface) {
    this.id = value.id;
    this.merchantId = value.merchantId;
    this.balance = value.balance;
    this.name = value.name;
    this.description = value.description;
    this.counterExist = value.counterExist;
  }
}

export enum ServiceKeys {
  PROVIDER_ID = 'providerId',
  ACCOUNT_NUMBER = 'accountNumber',
  MERCHANT_ID = 'merchantId',
}

export interface ServiceFilterProps {
  [ServiceKeys.PROVIDER_ID]?: FilterOperators<number>;
  [ServiceKeys.ACCOUNT_NUMBER]?: FilterOperators<string>;
  [ServiceKeys.MERCHANT_ID]?: FilterOperators<string>;
}

export type LoadService = Load<{ filter?: ServiceFilterProps; limit?: number; offset?: number }>;
