import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';
import { Service } from '@entities/Billing/Service';

export interface MerchantInterface {
  id: string;
  name?: string | null;
  description?: string | null;
  inn?: string | null;
  groupId?: string | null;
  balanceByService?: number | null;
  balanceByNds?: number | null;
  penalty?: number | null;
  balance?: number | null;
  contactName?: string | null;
  contactPhone?: string | null;
  contactEMail?: string | null;
  services?: Service[] | null;
  currentMonthPayed?: number | null;
  balanceDate?: number | null;
}

export class Merchant {
  public id: string;
  public name?: string | null;
  public description?: string | null;
  public inn?: string | null;
  public groupId?: string | null;
  public balanceByService?: number | null;
  public balanceByNds?: number | null;
  public balanceDate?: number | null;
  public penalty?: number | null;
  public balance?: number | null;
  public contactName?: string | null;
  public contactPhone?: string | null;
  public contactEMail?: string | null;
  public services?: Service[] | null;
  public currentMonthPayed?: number | null;

  public constructor(value: MerchantInterface) {
    this.id = value.id;
    this.name = value.name;
    this.inn = value.inn;
    this.groupId = value.groupId;
    this.balanceByService = value.balanceByService;
    this.balanceByNds = value.balanceByNds;
    this.penalty = value.penalty;
    this.balance = value.balance;
    this.contactName = value.contactName;
    this.contactEMail = value.contactEMail;
    this.contactPhone = value.contactPhone;
    this.services = value.services;
    this.description = value.description;
    this.currentMonthPayed = value.currentMonthPayed;
    this.balanceDate = value.balanceDate;
  }
}

export enum MerchantKeys {
  PROVIDER_ID = 'providerId',
  ACCOUNT_NUMBER = 'accountNumber',
  MERCHANT_GROUP_ID = 'merchantGroupId',
}

export interface MerchantFilterProps {
  [MerchantKeys.PROVIDER_ID]?: FilterOperators<number>;
  [MerchantKeys.ACCOUNT_NUMBER]?: FilterOperators<string>;
  [MerchantKeys.MERCHANT_GROUP_ID]?: FilterOperators<string>;
}

export type LoadMerchant = Load<
  { filter?: MerchantFilterProps; limit?: number; offset?: number },
  Merchant[]
>;
