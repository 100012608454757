import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';
import { Merchant } from '@entities/Billing/Merchant';

export interface MerchantGroupInterface {
  id: string;
  name?: string | null;
  inn?: string | null;
  description?: string | null;
  balanceByService?: number | null;
  balanceDate?: number | null;
  penalty?: number | null;
  balance?: number | null;
  balanceByNds?: number | null;
  paymentAccount?: string | null;
  bik?: string | null;
  correspondentAccount?: string | null;
  bank?: string | null;
  paymentMin?: number | null;
  paymentMax?: number | null;
  paymentInn: number;
  paymentName: string;
  paymentAcc: string;
  paymentCorAcc: string;
  paymentBik: string;
  paymentBank: string;
  contactName?: string | null;
  contactPhone?: string | null;
  contactEmail?: string | null;
  bankCommission?: number | null;
  bankCommissionType?: string | null;
  providerId?: number | null;
  offerLink?: string | null;
  merchants?: Merchant[] | null;
  bankCommissionIsUpper: boolean;
  currentMonthPayed?: number | null;
  lastPayedDate?: number | null;
  invoiceFileUrl?: string | null;
  tokenizationEnabled?: boolean;
}

export class MerchantGroup {
  public id: string;
  public name?: string | null;
  public inn?: string | null;
  public description?: string | null;
  public balanceByService?: number | null;
  public balanceDate?: number | null;
  public penalty?: number | null;
  public balance?: number | null;
  public balanceByNds?: number | null;
  public paymentAccount?: string | null;
  public bik?: string | null;
  public correspondentAccount?: string | null;
  public bank?: string | null;
  public paymentMin?: number | null;
  public paymentMax?: number | null;
  public paymentInn: number;
  public paymentName: string;
  public paymentAcc: string;
  public paymentCorAcc: string;
  public paymentBik: string;
  public paymentBank: string;
  public contactName?: string | null;
  public contactPhone?: string | null;
  public contactEmail?: string | null;
  public bankCommission?: number | null;
  public bankCommissionType?: string | null;
  public providerId?: number | null;
  public offerLink?: string | null;
  public merchants?: Merchant[] | null;
  public bankCommissionIsUpper: boolean;
  public currentMonthPayed?: number | null;
  public lastPayedDate?: number | null;
  public invoiceFileUrl?: string | null;
  public tokenizationEnabled?: boolean;

  public constructor(value: MerchantGroupInterface) {
    this.id = value.id;
    this.name = value.name;
    this.inn = value.inn;
    this.description = value.description;
    this.balanceByNds = value.balanceByService;
    this.balanceByService = value.balanceByService;
    this.balanceDate = value.balanceDate;
    this.penalty = value.penalty;
    this.balance = value.balance;
    this.balanceByNds = value.balanceByNds;
    this.paymentAccount = value.paymentAccount;
    this.bik = value.bik;
    this.correspondentAccount = value.correspondentAccount;
    this.bank = value.bank;
    this.paymentMin = value.paymentMin;
    this.paymentMax = value.paymentMax;
    this.paymentInn = value.paymentInn;
    this.paymentName = value.paymentName;
    this.paymentAcc = value.paymentAcc;
    this.paymentCorAcc = value.paymentCorAcc;
    this.paymentBik = value.paymentBik;
    this.paymentBank = value.paymentBank;
    this.contactName = value.contactName;
    this.contactPhone = value.contactPhone;
    this.contactEmail = value.contactEmail;
    this.bankCommission = value.bankCommission;
    this.bankCommissionType = value.bankCommissionType;
    this.providerId = value.providerId;
    this.offerLink = value.offerLink;
    this.merchants = value.merchants;
    this.bankCommissionIsUpper = value.bankCommissionIsUpper;
    this.currentMonthPayed = value.currentMonthPayed;
    this.lastPayedDate = value.lastPayedDate;
    this.invoiceFileUrl = value.invoiceFileUrl;
    this.tokenizationEnabled = value.tokenizationEnabled;
  }
}

export enum MerchantGroupKeys {
  PROVIDER_ID = 'providerId',
  ACCOUNT_NUMBER = 'accountNumber',
}

export interface MerchantGroupFilterProps {
  [MerchantGroupKeys.PROVIDER_ID]?: FilterOperators<number>;
  [MerchantGroupKeys.ACCOUNT_NUMBER]?: FilterOperators<string>;
}

export type LoadMerchantGroup = Load<
  { filter?: MerchantGroupFilterProps; limit?: number; offset?: number },
  MerchantGroup[]
>;
