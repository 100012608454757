import { action, observable, computed, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import Store from './Store';
import { endLoading, Loading } from '@stores/interfaces/Loading';
import {
  LoadPayment,
  Payment,
  PaymentFilterProps,
  PaymentInterface,
} from '@entities/Billing/Payment';
import {
  LoadPaymentReceipt,
  PaymentReceipt,
  PaymentReceiptInterface,
  // PaymentReceiptFilterProps,
} from '@entities/Billing/PaymentReceipt';

type PaymentGetLinkResponse = JrpcResponse<{ id: number; link: string }>;
type PaymentGetQrCodeLink = JrpcResponse<{ link: string }, { message: string }>;
type PaymentIndexResponse = JrpcResponse<{ items: PaymentInterface[]; total: number }>;
type PaymentReceiptIndexResponse = JrpcResponse<{
  items: PaymentReceiptInterface[];
  total: number;
}>;

export type Indication = {
  serviceName: string;
  counterSerialNumber: string;
  value: number | null;
};

class PaymentStore extends Store implements Loading {
  @observable private _loading: boolean;
  @observable private _payments: Payment[];
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _filter: PaymentFilterProps;
  @observable private _total: number;

  @action private _endLoading = endLoading(50).bind(this);

  constructor(services: Services) {
    super(services);

    this._loading = false;
    this._payments = [];
    this._limit = 0;
    this._offset = 0;
    this._filter = {};
    this._total = 0;
  }

  @action public createQrCore = async (params: {
    providerId: number;
    accountNumber: string;
  }): Promise<{ link: string; error?: string }> => {
    let link = '';
    let error: string | undefined;

    await this._services.billing.requests
      .qrCodeCreate({ params: { data: { ...params } } })
      .then(({ data: { result, error: errorData } }: AxiosResponse<PaymentGetQrCodeLink>) => {
        if (result) {
          link = result.link;
        }

        if (errorData && errorData.data) {
          error = errorData.data.message;
        }
      });

    return { link, error };
  };

  @action public sendQrOnEmail = async (params: {
    accountNumber: string;
    providerId: number;
    email: string;
  }): Promise<{ error?: string }> => {
    let error: string | undefined;

    await this._services.billing.requests
      .qrCodeSend({ params })
      .then(({ data }: AxiosResponse<PaymentGetQrCodeLink>) => {
        if (data.error || !data.result) {
          error = 'error';
        }
      });

    return { error };
  };

  @action public getLink = async (params: {
    providerId: number;
    accountNumber: string;
    merchantGroupId: string;
    amount: number;
    date: number;
    distribution?: Array<{ merchantId: string; amount: number }>;
    indications?: Indication[];
    bindPaymentToken?: boolean;
    paymentSource?: number;
    emailForFiscalReceipt?: string;
  }): Promise<{ value?: string; error?: string }> => {
    let link = '';

    let errorMessage: string | undefined = '';

    await this._services.billing.requests
      .paymentGetLink({
        params,
      })
      .then(({ data: { result, error } }: AxiosResponse<PaymentGetLinkResponse>) => {
        if (result) {
          link = result.link;
        }

        if (error) {
          errorMessage = error.message;
        }
      });

    return { value: link, error: errorMessage };
  };

  @action public getPaymentReceipt: LoadPaymentReceipt = async params => {
    let receipts: PaymentReceipt[] = [];
    // this._loading = true;

    await this._services.billing.requests
      .paymentReceiptIndex({
        params: {
          filter: params ? params.filter : {},
          limit: params?.limit || 10,
          offset: params?.offset || 0,
        },
      })
      .then(({ data: { result } }: AxiosResponse<PaymentReceiptIndexResponse>) => {
        if (result?.items && Array.isArray(result.items)) {
          receipts = result.items.map(value => new PaymentReceipt(value));
        }
      });
    // .finally(this._endLoading);

    return receipts;
  };

  @action public load: LoadPayment = async params => {
    let payments: Payment[] = [];
    this._loading = true;

    const filter = params?.filter || toJS(this._filter);
    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.billing.requests
      .paymentIndex({ params: { filter, limit, offset } })
      .then(({ data: { result } }: AxiosResponse<PaymentIndexResponse>) => {
        if (result?.items && Array.isArray(result.items)) {
          payments = result.items.map(value => new Payment(value));

          this._payments = payments;
        }

        if (typeof result?.total === 'number') {
          this._total = result.total;
        }
      })
      .finally(this._endLoading);

    return payments;
  };

  @action public cleanUp = (): void => {
    this._loading = false;
  };

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default PaymentStore;
