import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import { MapOrder, MapOrderInterface } from '@core/entities/Opencity/MapOrder';
import Store from './Store';
import { Pagination } from './interfaces/Pagination';

type MapOrdersResponse = JrpcResponse<{ items: MapOrderInterface[] }>;

class MapStore extends Store implements Pagination {
  @observable private _offset: number;
  @observable private _limit: number;
  @observable private _total: number;
  @observable private _orders: MapOrder[];

  public constructor(services: Services) {
    super(services);

    this._orders = [];
    this._limit = 1000;
    this._total = 1000;
    this._offset = 0;
  }

  @action public get = (): void => {
    const { _limit: limit, _offset: offset } = this;

    this._services.opencity.requests
      .mapIssueIndex({
        params: { limit, offset },
      })
      .then(({ data: { result } }: AxiosResponse<MapOrdersResponse>) => {
        if (result && Array.isArray(result.items)) {
          this._orders = result.items.map(value => new MapOrder(value));
        }
      });
  };

  @computed public get orders(): MapOrder[] {
    return toJS(this._orders);
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default MapStore;
