import { action, computed, observable, toJS } from 'mobx';
import { AxiosResponse } from 'axios';
import { mergeDeepRight, omit } from 'ramda';

import Services from '@services/index';
import OpencityActionNames from '@core/services/opencity/actionNames';
import SortDirections from '@constants/sort';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  AddOrderFilter,
  ClearOrderErrors,
  ClearOrderFilter,
  CountOrder,
  CreateOrder,
  ExportOrder,
  GetOrder,
  LoadOrder,
  Order,
  OrderCreationProps,
  OrderErrorKeys,
  OrderErrorProps,
  OrderFilterProps,
  OrderInterface,
  OrderKeys,
  OrderSortProps,
  RemoveOrderError,
  RemoveOrderFilter,
  UpdateOrder,
  OrderTabs,
  orderTabFilter,
  GetLastApplicant,
  LastApplicant,
} from '@core/entities/Opencity/Order';
import Store from './Store';
import SnackbarStore from './SnackbarStore';
import CommentStore from './CommentStore';
import OwnProfileStore from './OwnProfileStore';
import OrderDocumentStore from './OrderDocumentStore';
import ConfigurationStore from './ConfigurationStore';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';
import { Sort } from './interfaces/Sort';
import { endLoading, Loading, SetLoading } from './interfaces/Loading';
import { Entity } from './interfaces/Entity';
import { Errors } from './interfaces/Errors';
import { TaskState } from '@core/entities/Opencity/TaskState';

interface Totals {
  [OrderTabs.CLOSED]: number;
  [OrderTabs.DELAYED]: number;
  [OrderTabs.DONE]: number;
  [OrderTabs.IN_WORK]: number;
  [OrderTabs.NEW]: number;
  [OrderTabs.OK]: number;
  [OrderTabs.TO_PROVIDER]: number;
  [OrderTabs.ALL]: number;
  [OrderTabs.CONTROL]: number;
  [OrderTabs.PROBLEMATIC]: number;
  [OrderTabs.UNCONFIRMED]: number;
  [OrderTabs.NK]: number;
  [OrderTabs.HOUSE]: number;
  [OrderTabs.IS_IN_TIME]: number;
}

const DEFAULT_SORT: OrderSortProps[] = [{ field: OrderKeys.CREATED_AT, desc: SortDirections.DESC }];
const DEFAULT_FILTER: OrderFilterProps = {};
const DEFAULT_TOTALS: Totals = {
  all: 0,
  house: 0,
  closed: 0,
  delayed: 0,
  done: 0,
  inWork: 0,
  new: 0,
  ok: 0,
  toProvider: 0,
  control: 0,
  unconfirmed: 0,
  problematic: 0,
  nk: 0,
  isInTime: 0,
};

type IssueCountResponse = JrpcResponse<number>;
type IssueIndexResponse = JrpcResponse<{ items: OrderInterface[]; total: number }>;
type LastApplicantResponse = JrpcResponse<LastApplicant>;
type IssueCreateResponse = JrpcResponse<number, string>;
type IssueUpdateResponse = JrpcResponse<OrderInterface>;
type ReassignProviderResponse = JrpcResponse<OrderInterface[]>;
type ChangeClassifier = JrpcResponse<OrderInterface[]>;
type IssueExportResponse = JrpcResponse<string>;
type IssueNotice = JrpcResponse<boolean>;

interface OrderStoreRelations {
  ownProfileStore: OwnProfileStore;
  commentStore: CommentStore;
  snackbarStore: SnackbarStore;
  orderDocumentStore: OrderDocumentStore;
  configurationStore: ConfigurationStore;
}

class OrderStore extends Store<OrderStoreRelations>
  implements
    Loading,
    Pagination,
    Filter<OrderFilterProps, OrderKeys>,
    Sort<OrderSortProps>,
    Entity<
      Order,
      { filter?: OrderFilterProps; limit?: number; offset?: number },
      number,
      OrderCreationProps,
      OrderFilterProps
    >,
    Errors<OrderErrorProps, OrderErrorKeys> {
  @observable private _orders: Order[];
  @observable private _filter: OrderFilterProps;
  @observable private _sort: OrderSortProps[];
  @observable private _isWithoutDeleted: boolean;
  @observable private _errors: OrderErrorProps;
  @observable private _offset: number;
  @observable private _limit: number;
  @observable private _total: number;
  @observable private _loading: boolean;
  @observable private _requestInProcess: boolean;
  @observable private _totals: Totals;

  @action private _endLoading = endLoading(500).bind(this);

  public constructor(services: Services, relations: OrderStoreRelations) {
    super(services, relations);

    this._orders = [];
    this._filter = DEFAULT_FILTER;
    this._sort = DEFAULT_SORT;
    this._isWithoutDeleted = false;
    this._errors = {};
    this._totals = DEFAULT_TOTALS;
    this._loading = false;
    this._offset = 0;
    this._limit = 20;
    this._total = 0;
    this._requestInProcess = false;
  }

  @action public create: CreateOrder = async ({ address, phone, ...rest }, options) => {
    this._loading = true;

    let creationResult = false;

    await this._services.opencity.requests
      .issueCreate({
        params: {
          data: {
            ...rest,
            buildingNumber: address.buildingNumber,
            flatNumber: address.flatNumber,
            houseNumber: address.houseNumber,
            phone: phone?.replace(/\D/g, ''),
            street: address.streetName,
          },
          ...(this._relations.ownProfileStore.user && {
            userId: this._relations.ownProfileStore.user.id,
          }),
        },
      })
      .then(({ data: { result, error } }: AxiosResponse<IssueCreateResponse>) => {
        if (result) {
          creationResult = Boolean(result);

          if (options?.notification) {
            this._relations.snackbarStore.setMessage('Заявка успешно создана', 'success');
          }
        }

        if (error) {
          if (error.code === 120000) {
            this._errors = {
              ...this._errors,
              flatAccount: 'Лицевой счёт не найден или не соответсвует выбранному адресу',
            };
          }
          if (error.code === 106009) {
            if (options?.notification) {
              this._relations.snackbarStore.setMessage(
                'Извините произошла непредвиденная ошибка',
                'error',
              );
            }
          }
          if (error.data) {
            if (options?.notification) {
              this._relations.snackbarStore.setMessage(error.data, 'error');
            }
          }
        }
      })
      .finally(this._endLoading);

    return creationResult;
  };

  @action public issueNotice = async (): Promise<boolean> => {
    let noticeResult = false;
    if (this._relations.ownProfileStore.user) {
      await this._services.opencity.requests
        .issueNotice({
          params: {
            userId: this._relations.ownProfileStore.user.id,
          },
        })
        .then(({ data: { result } }: AxiosResponse<IssueNotice>) => {
          if (typeof result === 'boolean') {
            noticeResult = result;
          }
        });
    }

    return noticeResult;
  };

  @action public update: UpdateOrder = async ({ payload, entity }) => {
    let order: Order | null = null;

    if (payload && payload.transition && entity && this._relations.ownProfileStore.user) {
      const { orderParams, transition, fileIds, workOrderFileIds } = payload;

      if (workOrderFileIds) {
        for (const fileId of workOrderFileIds) {
          await this._relations.orderDocumentStore.create({
            fileId: fileId,
            orderId: entity.id,
          });
        }
      }

      await this._services.opencity.requests[transition as OpencityActionNames]({
        params: {
          transition_name: transition,
          context: {
            userId: this._relations.ownProfileStore.user.id,
            providerId: orderParams?.providerId,
            providerIds: orderParams?.providerIds,
          },
          entity: {
            name: 'issue',
            id: entity.id,
            parentId: entity.parentId || null,
            problemId: entity.problemId || null,
            fileIds,
            ...orderParams,
            // orderParams,
          },
        },
      }).then(async ({ data: { result, error } }: AxiosResponse<IssueUpdateResponse>) => {
        if (result) {
          const updatedOrder: Order = new Order(result);

          order = updatedOrder;

          // this._orders = [updatedOrder];
          //TODO возможно стоит пересмотреть

          this._relations.snackbarStore.setMessage('Заявка изменена', 'success');
        }

        if (error) {
          this._relations.snackbarStore.setMessage(
            'Во время изменения статуcа произошла ошибка',
            'error',
          );
        }
      });
    }

    return order;
  };

  @action public reassignProvider = async (
    providerId: number,
    id: number,
    comment?: string,
  ): Promise<Order | null> => {
    let order: Order | null = null;

    if (this._relations.ownProfileStore.user) {
      await this._services.opencity.requests
        .issueChangeProvider({
          params: {
            filter: { id },
            providerId,
            comment,
            userId: this._relations.ownProfileStore.user.id,
          },
        })
        .then(async ({ data: { result, error } }: AxiosResponse<ReassignProviderResponse>) => {
          if (result) {
            const updatedOrder: Order = new Order(result[0]);

            order = updatedOrder;

            this._orders = [updatedOrder];

            this._relations.snackbarStore.setMessage('Заявка изменена', 'success');
          }

          if (error) {
            this._relations.snackbarStore.setMessage(
              'Во время изменения статуcа произошла ошибка',
              'error',
            );
          }
        });
    }

    return order;
  };

  @action public changeClassifier = async (
    classifierId: number,
    id: number,
    comment?: string,
  ): Promise<Order | null> => {
    let order: Order | null = null;

    if (this._relations.ownProfileStore.user) {
      await this._services.opencity.requests
        .issueChangeClassifier({
          params: {
            filter: { id },
            classifierId,
            comment,
            userId: this._relations.ownProfileStore.user.id,
          },
        })
        .then(async ({ data: { result, error } }: AxiosResponse<ChangeClassifier>) => {
          if (result) {
            const updatedOrder: Order = new Order(result[0]);

            order = updatedOrder;

            this._orders = [updatedOrder];

            this._relations.snackbarStore.setMessage('Заявка изменена', 'success');
          }

          if (error) {
            this._relations.snackbarStore.setMessage(
              'Во время изменения статуcа произошла ошибка',
              'error',
            );
          }
        });
    }

    return order;
  };

  @action public load: LoadOrder = async params => {
    this._loading = true;
    this._requestInProcess = true;

    let orders: Order[] = [];

    const { _limit, _offset, _filter, _sort } = this;

    const filter = (params && params.filter) || toJS(_filter);
    const sort = (params && params.sort) || _sort;
    const limit = (params && params.limit) || _limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : _offset;

    this._filter = filter;
    this._sort = sort;
    this._limit = limit;
    this._offset = offset;

    await this._services.opencity.requests
      .issueIndex({
        params: {
          filter: this.addTabFilter(filter),
          limit,
          offset,
          sort,
        },
      })
      .then(({ data: { result } }: AxiosResponse<IssueIndexResponse>) => {
        if (result) {
          const { items, total } = result;
          if (Array.isArray(items)) {
            orders = items.map(value => new Order(value));
            this._orders = orders;
          }

          if (typeof total === 'number') {
            this._total = total;
          }

          this._relations.configurationStore.changeSettings('filter', this.addTabFilter(filter));
          this._relations.configurationStore.changeSettings('limit', limit);
          this._relations.configurationStore.changeSettings('offset', offset);
        }
      })
      .finally(() => {
        this._endLoading();
        setTimeout(() => {
          this._requestInProcess = false;
        }, 500);
      });

    return orders;
  };

  @action public exportOrders: ExportOrder = async params => {
    let link = '';

    const { _limit, _offset, _filter, _sort, _isWithoutDeleted } = this;

    const filter = (params && params.filter) || toJS(_filter);
    const isWithoutDeleted = (params && params.isWithoutDeleted) || _isWithoutDeleted;
    const sort = (params && params.sort) || _sort;
    const limit = (params && params.limit) || _limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : _offset;
    const select = params?.select
      ? toJS(params.select).filter(item => !['action', 'attribute'].includes(item))
      : [];

    await this._services.opencity.requests
      .issueExport({
        params: {
          filter: this.addTabFilter(filter),
          isWithoutDeleted,
          limit,
          offset,
          sort,
          select,
        },
      })
      .then(({ data: { result } }: AxiosResponse<IssueExportResponse>) => {
        if (result) {
          link = result;
        }
      })
      .finally(this._endLoading);

    return link;
  };

  @action public makeOrderViewed = (orderId: number): void => {
    this._services.opencity.requests.issueView({
      params: {
        orderId: orderId,
        ...(this._relations.ownProfileStore.user && {
          userId: this._relations.ownProfileStore.user.id,
        }),
      },
    });
  };

  @action public count: CountOrder = async params => {
    let total: number = this._total;

    const filter = (params && params.filter) || {};
    await this._services.opencity.requests
      .issueCount({
        params: {
          filter,
        },
      })
      .then(({ data: { result } }: AxiosResponse<IssueCountResponse>) => {
        if (result) {
          total = result;
        }
      });

    return total;
  };

  @action public getTotals = (customFilter?: OrderFilterProps): void => {
    [
      OrderTabs.ALL,
      OrderTabs.HOUSE,
      OrderTabs.CLOSED,
      OrderTabs.DELAYED,
      OrderTabs.DONE,
      OrderTabs.IN_WORK,
      OrderTabs.NEW,
      OrderTabs.OK,
      OrderTabs.TO_PROVIDER,
      OrderTabs.CONTROL,
      OrderTabs.PROBLEMATIC,
      OrderTabs.UNCONFIRMED,
      OrderTabs.NK,
      OrderTabs.IS_IN_TIME,
    ].forEach(
      async (state: OrderTabs): Promise<void> => {
        if (state === OrderTabs.HOUSE) {
          this._totals[state as keyof Totals] = await this.count({
            filter: {
              ...this.addTabFilter(
                customFilter ? { ...customFilter, flatId: undefined } : {},
                state,
              ),
            },
          });
        } else {
          this._totals[state as keyof Totals] = await this.count({
            filter: { ...this.addTabFilter(customFilter || {}, state) },
          });
        }
      },
    );
  };

  @action public getTotalsByState = async (
    state: OrderTabs,
    filter: OrderFilterProps,
  ): Promise<void> => {
    if (state === OrderTabs.HOUSE) {
      this._totals[state as keyof Totals] = await this.count({
        filter: { ...this.addTabFilter({ ...filter, flatId: undefined }, state) },
      });
    } else {
      this._totals[state as keyof Totals] = await this.count({
        filter: { ...this.addTabFilter(filter, state) },
      });
    }
  };

  @action public addFilter: AddOrderFilter = filter => {
    this._filter = mergeDeepRight(this._filter, filter);
  };

  @action public removeFilter: RemoveOrderFilter = keys => {
    this._filter = omit(keys, this._filter);
  };

  @action public removeError: RemoveOrderError = key => {
    this._errors = omit([key], this.errors);
  };

  @action public clearErrors: ClearOrderErrors = () => {
    this._errors = {};
  };

  @action public clearFilter: ClearOrderFilter = () => {
    this._filter = {};
  };

  @action public setOffset: SetOffset = offset => (this._offset = offset);

  @action public setLimit: SetLimit = limit => (this._limit = limit);

  @action public cleanUp = (): void => {
    this._orders = [];
    this._filter = DEFAULT_FILTER;
    this._sort = DEFAULT_SORT;
    this._loading = false;
    this._errors = {};
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._totals = DEFAULT_TOTALS;
  };

  @action public get: GetOrder = async filter => {
    let order: Order | undefined = await this._orders.find(value => {
      if (
        value.id === filter[OrderKeys.ID]?.$eq &&
        value.trackerId === filter[OrderKeys.TRACKER]?.$eq
      ) {
        return value;
      }
    });

    if (!order) {
      this._loading = true;

      await this._services.opencity.requests
        .issueIndex({ params: { filter, sort: DEFAULT_SORT, limit: 1 } })
        .then(({ data: { result } }: AxiosResponse<IssueIndexResponse>) => {
          if (result && Array.isArray(result.items)) {
            order = result.items[0];
          }
        })
        .finally(this._endLoading);
    }

    return order;
  };

  @action public getLastApplicant: GetLastApplicant = async params => {
    let lastApplicant: LastApplicant | undefined = undefined;

    this._loading = true;

    await this._services.opencity.requests
      .flatAccountGetLastApplicant({ params })
      .then(({ data: { result } }: AxiosResponse<LastApplicantResponse>) => {
        if (result) {
          lastApplicant = result;
        }
      })
      .finally(this._endLoading);

    return lastApplicant;
  };

  @action public getFromServer: GetOrder = async filter => {
    let order: Order | undefined = undefined;

    this._loading = true;

    await this._services.opencity.requests
      .issueIndex({ params: { filter, sort: DEFAULT_SORT } })
      .then(({ data: { result } }: AxiosResponse<IssueIndexResponse>) => {
        if (result && Array.isArray(result.items)) {
          order = result.items[0];
        }
      })
      .finally(this._endLoading);

    return order;
  };

  @action public loadComments = (id: number): void => {
    this._relations.commentStore.load({ filter: { orderId: { $eq: id } }, limit: 0 });
  };

  @action public loadTaskStates = (id: number, parentId?: number | null): void => {
    this._relations.commentStore.loadTaskState({
      filter: {
        $or: [
          {
            taskId: { $eq: parentId || id },
          },
          {
            parentTaskId: { $eq: parentId || id },
          },
        ],
      },
      limit: 0,
    });
  };

  @action public getTaskState = (filter: any): Promise<TaskState[]> => {
    return this._relations.commentStore.getTaskState({
      filter,
      limit: 0,
    });
  };

  @action public setLoading: SetLoading = loading => {
    this._loading = loading;
  };

  private addTabFilter = (filter: OrderFilterProps, tabState?: OrderTabs): OrderFilterProps => {
    return {
      ...orderTabFilter[tabState || this._relations.configurationStore.settings.tabState],
      ...filter,
    };
  };

  @computed public get list(): Order[] {
    return toJS(this._orders);
  }

  @computed public get filter(): OrderFilterProps {
    return toJS(this._filter);
  }

  @computed public get errors(): OrderErrorProps {
    return toJS(this._errors);
  }

  @computed public get stateFilter(): string | undefined {
    return this._filter?.state?.$eq;
  }

  @computed public get sort(): OrderSortProps[] {
    return toJS(this._sort);
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get requestInProcess(): boolean {
    return this._requestInProcess;
  }
  @computed public get totals(): Totals {
    return toJS(this._totals);
  }
}

export default OrderStore;
