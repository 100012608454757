import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@services/index';
import {
  Account,
  AccountInterface,
  AccountFilterProps,
  AccountKeys,
  LoadAccount,
} from '@entities/Billing/Account';
import Store from './Store';
import { Loading, endLoading } from '@stores/interfaces/Loading';
import { Entity } from '@stores/interfaces/Entity';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';

type AccountIndexResponse = JrpcResponse<{ items: AccountInterface[]; total: number }>;

class AccountStore extends Store
  implements
    Loading,
    Pagination,
    Entity<Account, { filter?: AccountFilterProps; limit?: number; offset?: number }>,
    Filter<AccountFilterProps, AccountKeys> {
  @observable private _accounts: Account[];
  @observable private _filter: AccountFilterProps;
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;
  @observable private _needToSaveTemplate: boolean;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services) {
    super(services);

    this._accounts = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._needToSaveTemplate = true;
  }

  @action public setLimit: SetLimit = limit => {
    this._limit = limit;
  };

  @action public setOffset: SetOffset = offset => {
    this._offset = offset;
  };

  @action public load: LoadAccount = async params => {
    let accounts: Account[] = [];

    this._loading = true;

    const filter = params?.filter || toJS(this._filter);
    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.billing.requests
      .accountIndex({ params: { filter, limit, offset } })
      .then(({ data: { result } }: AxiosResponse<AccountIndexResponse>) => {
        if (result?.items && Array.isArray(result.items)) {
          accounts = result.items.map(value => new Account(value));

          this._accounts = accounts;
        }

        if (typeof result?.total === 'number') {
          this._total = result.total;
        }
      })
      .finally(this._endLoading);

    return accounts;
  };

  @action public cleanUp = (): void => {
    this._accounts = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  };

  @action public toggleSaveTemplate = (): void => {
    this._needToSaveTemplate = !this._needToSaveTemplate;
  };

  @computed public get list(): Account[] {
    return toJS(this._accounts);
  }

  @computed public get filter(): AccountFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get needToSaveTemplate(): boolean {
    return this._needToSaveTemplate;
  }
}

export default AccountStore;
