import { observable, action, computed, toJS } from 'mobx';
import { omit, mergeDeepRight } from 'ramda';
import { AxiosResponse } from 'axios';

import SortDirections from '@constants/sort';
import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  ShutdownInterface,
  ShutdownCreationProps,
  ShutdownFilterProps,
  ShutdownSortProps,
  Shutdown,
  ShutdownKeys,
  AddShutdownFilters,
  RemoveShutdownFilters,
  ClearShutdownFilters,
  ShutdownSortKeys,
  LoadShutdown,
  CountShutdown,
  GetShutdown,
  CreateShutdown,
  UpdateShutdown,
} from '@core/entities/Opencity/Shutdown';
import Store from './Store';
import OwnProfileStore from './OwnProfileStore';
import SnackbarStore from './SnackbarStore';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';
import { Sort } from './interfaces/Sort';
import { Loading, endLoading, SetLoading } from './interfaces/Loading';
import { Entity } from './interfaces/Entity';

type InterruptIndexResponse = JrpcResponse<{ items: ShutdownInterface[]; total: number }>;
type InterruptCountResponse = JrpcResponse<number>;
type InterruptCreateResponse = JrpcResponse<ShutdownInterface>;
type InterruptUpdateResponse = JrpcResponse<ShutdownInterface>;

const DEFAULT_SORT: ShutdownSortProps[] = [
  { field: ShutdownSortKeys.DATE_START, desc: SortDirections.DESC },
];

interface ShutdownStoreRelations {
  ownProfileStore: OwnProfileStore;
  snackbarStore: SnackbarStore;
}

class ShutdownStore extends Store<ShutdownStoreRelations>
  implements
    Pagination,
    Loading,
    Filter<ShutdownFilterProps, ShutdownKeys>,
    Sort<ShutdownSortProps>,
    Entity<
      Shutdown,
      {
        filter?: ShutdownFilterProps;
        sort?: ShutdownSortProps[];
        limit?: number;
        offset?: number;
      },
      number,
      ShutdownCreationProps,
      ShutdownFilterProps
    > {
  @observable private _shutdowns: Shutdown[];
  @observable private _filter: ShutdownFilterProps;
  @observable private _sort: ShutdownSortProps[];
  @observable private _total: number;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services, relations: ShutdownStoreRelations) {
    super(services, relations);

    this._shutdowns = [];
    this._filter = {};
    this._sort = DEFAULT_SORT;
    this._offset = 0;
    this._limit = 20;
    this._total = 0;
    this._loading = false;
  }

  @action public load: LoadShutdown = async params => {
    let shutdowns: Shutdown[] = [];

    this._loading = true;

    const { _filter, _sort, _limit, _offset } = this;

    const filter = (params && params.filter) || toJS(_filter);
    const sort = (params && params.sort) || _sort;
    const limit = (params && params.limit) || _limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : _offset;

    this._filter = filter;
    this._sort = sort;
    this._limit = limit;
    this._offset = offset;

    await this._services.opencity.requests
      .interruptIndex({
        params: {
          filter,
          limit,
          offset,
          sort,
        },
      })
      .then(({ data: { result } }: AxiosResponse<InterruptIndexResponse>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            shutdowns = items.map<Shutdown>(value => new Shutdown(value));

            this._shutdowns = shutdowns;
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return shutdowns;
  };

  @action public count: CountShutdown = async params => {
    this._loading = true;

    let total = 0;

    const filter = (params && params.filter) || toJS(this._filter);

    this._filter = filter;

    await this._services.opencity.requests
      .interruptCount({
        params: { filter },
      })
      .then(({ data: { result } }: AxiosResponse<InterruptCountResponse>) => {
        if (result) {
          total = result;

          this._total = total;
        }
      })
      .finally(this._endLoading);

    return total;
  };

  @action public get: GetShutdown = async filter => {
    let shutdown: Shutdown | undefined = this._shutdowns.find(
      value => value.id === filter[ShutdownKeys.ID],
    );

    if (!shutdown) {
      this._loading = true;

      await this._services.opencity.requests
        .interruptIndex({ params: { filter } })
        .then(({ data: { result } }: AxiosResponse<InterruptIndexResponse>) => {
          if (result && Array.isArray(result.items)) {
            shutdown = result.items[0];
          }
        })
        .finally(this._endLoading);
    }
    return shutdown;
  };

  @action public create: CreateShutdown = async ({
    address,
    dateEnd,
    dateStart,
    reason,
    sender,
    type,
    body,
    telephoneMessage,
    cpt,
  }) => {
    this._loading = true;

    const userId: number | undefined = this._relations.ownProfileStore.user?.id;

    let shutdown: Shutdown | null = null;

    await this._services.opencity.requests
      .interruptCreate({
        params: {
          data: {
            body,
            dateEnd,
            dateStart,
            reasonId: reason,
            receivers: {
              flatIds: address.flatIds,
              houseIds: address.houseIds,
            },
            senderId: sender,
            typeId: type,
            userId,
            telephoneMessage,
            cpt,
          },
        },
      })
      .then(({ data: { result } }: AxiosResponse<InterruptCreateResponse>): void => {
        if (result) {
          shutdown = new Shutdown(result);

          this._relations.snackbarStore.setMessage('Отключение успешно создано', 'success');
        }
      })
      .finally(this._endLoading);

    return shutdown;
  };

  @action public update: UpdateShutdown = async ({ payload, entity }) => {
    this._loading = true;

    const userId: number | undefined = this._relations.ownProfileStore.user?.id;

    let shutdown: Shutdown | null = null;

    await this._services.opencity.requests
      .interruptUpdate({
        params: {
          data: {
            body: payload?.body,
            dateEnd: payload?.dateEnd,
            dateStart: payload?.dateStart,
            reasonId: payload?.reason,
            receivers: {
              flatIds: payload?.address?.flatIds,
              houseIds: payload?.address?.houseIds,
            },
            senderId: payload?.sender,
            typeId: payload?.type,
            userId,
            telephoneMessage: payload?.telephoneMessage,
            cpt: payload?.cpt,
          },
          filter: {
            id: entity?.id,
          },
        },
      })
      .then(({ data: { result } }: AxiosResponse<InterruptCreateResponse>): void => {
        if (result) {
          shutdown = new Shutdown(result);

          this._relations.snackbarStore.setMessage('Отключение успешно сохранено', 'success');
        }
      })
      .finally(this._endLoading);

    return shutdown;
  };

  @action public addFilter: AddShutdownFilters = filter => {
    this._filter = mergeDeepRight(this.filter, filter);
  };

  @action public removeFilter: RemoveShutdownFilters = keys => {
    this._filter = omit(keys, this.filter);
  };

  @action public clearFilter: ClearShutdownFilters = (): void => {
    this._filter = {};
  };

  @action public setLimit: SetLimit = limit => {
    this._limit = limit;
  };

  @action public setOffset: SetOffset = offset => {
    this._offset = offset;
  };

  @action public cleanUp = (): void => {
    this._shutdowns = [];
    this._filter = {};
    this._sort = [{ field: ShutdownSortKeys.DATE_START, desc: SortDirections.DESC }];
    this._offset = 0;
    this._limit = 20;
    this._total = 0;
    this._loading = false;
  };

  @action public setLoading: SetLoading = loading => {
    this._loading = loading;
  };

  @computed public get filter(): ShutdownFilterProps {
    return toJS<ShutdownFilterProps>(this._filter);
  }

  @computed public get list(): Shutdown[] {
    return toJS(this._shutdowns);
  }

  @computed public get sort(): ShutdownSortProps[] {
    return this._sort;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default ShutdownStore;
