import React, { useEffect, FC } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

import ThemeProvider from '@public/components/Theme';
import { useSignStore } from './hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      marginTop: `${theme.spacing(12)}px`,
      width: `${theme.spacing(20)}px !important`,
      height: `${theme.spacing(20)}px !important`,
      marginBottom: `${theme.spacing(12)}px`,
    },
    text: {
      color: theme.palette.text.primary,
    },
  }),
);

const EmailConfirm: FC<{ setMountApprove: (value: boolean) => void }> = ({ setMountApprove }) => {
  const classes = useStyles();
  const { userCodeConfirm } = useSignStore();

  useEffect(() => {
    const url = new URL(window.location.href);
    const email = url.searchParams.get('email');
    const code = url.searchParams.get('code');
    if (email && code) {
      userCodeConfirm(email, code).then(() =>
        setTimeout(() => {
          window.location.href = '/';
          setMountApprove(true);
        }, 500),
      );
    }
  }, []);

  return (
    <ThemeProvider>
      <Grid container={true} direction={'column'} alignItems={'center'}>
        <Grid item={true}>
          <CircularProgress className={classes.loader} />
        </Grid>
        <Grid item={true}>
          <Typography className={classes.text}>Загрузка...</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default EmailConfirm;
