import { AddFilter, ClearFilter, FilterOperators } from '@stores/interfaces/Filter';
import { Load, Create } from '@stores/interfaces/Entity';
import { OrderState, OrderStateLabels } from './Order';

export interface CommentInterface {
  id: number;
  orderId?: number | null;
  taskId?: number | null;
  providerId?: number | null;
  providerCommonId?: number | null;
  userId?: number | null;
  status?: string | null;
  reason?: string | null;
  organizationInfo?: string | null;
  timestamp?: number | null;
  userFullName?: string | null;
  userRole?: string | null;
  comment?: string | null;
  state?: OrderState | null;
}

export class Comment {
  public id: number;
  public orderId?: number | null;
  public taskId?: number | null;
  public providerId?: number | null;
  public providerCommonId?: number | null;
  public userId?: number | null;
  public status?: string | null;
  public reason?: string | null;
  public organizationInfo?: string | null;
  public timestamp?: number | null;
  public userFullName?: string | null;
  public userRole?: string | null;
  public comment?: string | null;
  public state?: OrderState | null;

  public constructor(value: CommentInterface) {
    this.id = value.id;
    this.orderId = value.orderId;
    this.taskId = value.taskId;
    this.providerId = value.providerId;
    this.providerCommonId = value.providerCommonId;
    this.userId = value.userId;
    this.status = value.status;
    this.reason = value.reason;
    this.organizationInfo = value.organizationInfo;
    this.timestamp = value.timestamp;
    this.userFullName = value.userFullName;
    this.userRole = value.userRole;
    this.comment = value.comment;
    this.state = value.state;
  }

  public get stateLabel(): string | undefined {
    if (this.state) {
      return Object.values(OrderStateLabels).filter(value => value.key === this.state)[0].singular;
    }
  }
}

export enum CommentKeys {
  ORDER_ID = 'orderId',
  TASK_ID = 'taskId',
  PARENT_TASK_ID = 'parentTaskId',
}

export interface CommentFilterProps {
  [CommentKeys.ORDER_ID]?: FilterOperators<number>;
  [CommentKeys.TASK_ID]?: FilterOperators<number>;
  [CommentKeys.PARENT_TASK_ID]?: FilterOperators<number>;
}

interface AddCommentProps {
  taskId: number;
  comment: string;
}

export type AddCommentFilter = AddFilter<CommentFilterProps>;

export type ClearCommentFilter = ClearFilter;

export type LoadComment = Load<{ filter?: CommentFilterProps; limit?: number }, Comment[]>;

export type AddComment = Create<AddCommentProps, Promise<boolean>>;
