enum BillingActionNames {
  ACCOUNT_INDEX = 'accountIndex',
  ACCOUNT_EXISTS = 'accountExists',
  COUNTER_VALUE_CREATE = 'counterValueCreate',
  COUNTER_INDEX = 'counterIndex',
  MERCHANT_INDEX = 'merchantIndex',
  MERCHANT_GROUP_INDEX = 'merchantGroupIndex',
  SERVICE_INDEX = 'serviceIndex',
  QR_CODE_CREATE = 'qrCodeCreate',
  QR_CODE_SEND = 'qrCodeSend',
  PAYMENT_RECEIPT_INDEX = 'paymentReceiptIndex',
  PAYMENT_INDEX = 'paymentIndex',
  PAYMENT_GET_LINK = 'paymentGetLink',
  USER_ACCOUNT_INDEX = 'userAccountIndex',
  USER_ACCOUNT_CREATE = 'userAccountCreate',
  USER_ACCOUNT_UPDATE = 'userAccountUpdate',
  ACCRUAL_INDEX = 'accrualIndex',
  ORGANIZATION_INDEX = 'organizationIndex',
}

export default BillingActionNames;
