enum OpencityMethods {
  AUTH_PASSWORD_RESET = 'opencity.auth.password.reset',
  AUTH_PASSWORD_RECOVERY = 'opencity.auth.password.recovery',
  AUTH_PASSWORD_UPDATE = 'opencity.auth.password.update',
  ADDRESS_SEARCH = 'opencity.address.search',
  CLASSIFIER_SEARCH = 'opencity.classifier.search',
  MAP_ISSUE_INDEX = 'opencity.map.issue.index',
  ISSUE_CREATE = 'opencity.issue.create',
  ISSUE_UPDATE = 'opencity.issue.update',
  ISSUE_NEW_CLOSED_BY_COMMUNAL_UPDATE = 'opencity.issue.new.closed_by_communal.update',
  ISSUE_NEW_TO_EXECUTE_UPDATE = 'opencity.issue.new.to_execute.update',
  ISSUE_NEW_DELETED_UPDATE = 'opencity.issue.new.deleted.update',
  ISSUE_NEW_DELAYED_UPDATE = 'opencity.issue.new.delayed.update',
  ISSUE_NEW_TO_PROVIDER_UPDATE = 'opencity.issue.new.to_provider.update',
  ISSUE_DELAYED_RESTORED_UPDATE = 'opencity.issue.delayed.restored.update',
  ISSUE_RESTORED_DELAYED_UPDATE = 'opencity.issue.restored.delayed.update',
  ISSUE_RESTORED_TO_PROVIDER_UPDATE = 'opencity.issue.restored.to_provider.update',
  ISSUE_RESTORED_DELETED_UPDATE = 'opencity.issue.restored.deleted.update',
  ISSUE_TO_EXECUTE_DELETED_UPDATE = 'opencity.issue.to_execute.deleted.update',
  ISSUE_TO_EXECUTE_TO_PROVIDER_UPDATE = 'opencity.issue.to_execute.to_provider.update',
  ISSUE_TO_EXECUTE_DELAYED_UPDATE = 'opencity.issue.to_execute.delayed.update',
  ISSUE_TO_PROVIDER_IN_WORK_UPDATE = 'opencity.issue.to_provider.in_work.update',
  ISSUE_TO_PROVIDER_DONE_UPDATE = 'opencity.issue.to_provider.done.update',
  ISSUE_IN_WORK_DONE_UPDATE = 'opencity.issue.in_work.done.update',
  ISSUE_DONE_DELETED_UPDATE = 'opencity.issue.done.deleted.update',
  ISSUE_DONE_TO_PROVIDER_UPDATE = 'opencity.issue.done.to_provider.update',
  ISSUE_DONE_OK_UPDATE = 'opencity.issue.done.ok.update',
  ISSUE_DONE_CLOSED_UPDATE = 'opencity.issue.done.closed.update',
  ISSUE_RESTORED_DONE_UPDATE = 'opencity.issue.restored.done.update',
  ISSUE_OK_UNCONFIRMED_UPDATE = 'opencity.issue.ok.unconfirmed.update',
  ISSUE_OK_CLOSED_BY_CITIZEN_UPDATE = 'opencity.issue.ok.closed_by_citizen.update',
  ISSUE_OK_CLOSED_UPDATE = 'opencity.issue.ok.closed.update',
  ISSUE_OK_CLOSED_BY_STD_UPDATE = 'opencity.issue.ok.closed_by_std.update',
  ISSUE_OK_CLOSED_AUTO_UPDATE = 'opencity.issue.ok.closed_auto.update',
  ISSUE_UNCONFIRMED_TO_EXECUTE_UPDATE = 'opencity.issue.unconfirmed.to_execute.update',
  ISSUE_UNCONFIRMED_TO_PROVIDER_UPDATE = 'opencity.issue.unconfirmed.to_provider.update',
  ISSUE_UNCONFIRMED_CLOSED_BY_ANALYST_UPDATE = 'opencity.issue.unconfirmed.closed_by_analyst.update',
  ISSUE_TO_PROVIDER_DELETED_UPDATE = 'opencity.issue.to_provider.deleted.update',
  ISSUE_TO_PROVIDER_DELAYED_UPDATE = 'opencity.issue.to_provider.delayed.update',
  ISSUE_DELETED_RESTORED_UPDATE = 'opencity.issue.deleted.restored.update',
  ISSUE_NEW_REJECTED_UPDATE = 'opencity.issue.new.rejected.update',
  ISSUE_INDEX = 'opencity.issue.index',
  ISSUE_COUNT = 'opencity.issue.count',
  ISSUE_EXPORT = 'opencity.issue.export',
  ISSUE_COMMENT_INDEX = 'opencity.issue.comment.index',
  ISSUE_DOCUMENT_CREATE = 'opencity.issue.document.create',
  ISSUE_DOCUMENT_INDEX = 'opencity.issue.document.index',
  FLAT_ACCOUNT_INDEX = 'opencity.flat.account.index',
  FLAT_ACCOUNT_COUNT = 'opencity.flat.account.count',
  FLAT_ACCOUNT_OWNER_CREATE = 'opencity.flat.account.owner.create',
  FLAT_ACCOUNT_OWNER_UPDATE = 'opencity.flat.account.owner.update',
  FLAT_ACCOUNT_GET_LAST_APPLICANT = 'opencity.flat.account.get_last_applicant',
  USER_COUNT = 'opencity.user.count',
  USER_CREATE = 'opencity.user.create',
  USER_INDEX = 'opencity.user.index',
  USER_DELETE = 'opencity.user.delete',
  ORGANIZATION_COUNT = 'opencity.organization.count',
  ORGANIZATION_INDEX = 'opencity.organization.index',
  ORGANIZATION_RATING_INDEX = 'opencity.organization.rating.index',
  ORGANIZATION_PREFERABLE_INDEX = 'opencity.organization.preferable.index',
  INTERRUPT_INDEX = 'opencity.interrupt.index',
  INTERRUPT_COUNT = 'opencity.interrupt.count',
  INTERRUPT_CREATE = 'opencity.interrupt.create',
  INTERRUPT_UPDATE = 'opencity.interrupt.update',
  PROFILE_INDEX = 'opencity.profile.index',
  PROFILE_CREATE = 'opencity.profile.create',
  PROFILE_UPDATE = 'opencity.profile.update',
  PROFILE_ACCOUNT_CREATE = 'opencity.profile.account.create',
  PROFILE_ACCOUNT_DELETE = 'opencity.profile.account.delete',
  AVATAR_CREATE = 'opencity.profile.avatar.create',
  AVATAR_DELETE = 'opencity.profile.avatar.delete',
  AVATAR_INDEX = 'opencity.profile.avatar.index',
  AVATAR_UPDATE = 'opencity.profile.avatar.update',
  GIS_STREET_INDEX = 'opencity.gis.street.index',
  GIS_STREET_COUNT = 'opencity.gis.street.count',
  GIS_HOUSE_INDEX = 'opencity.gis.house.index',
  GIS_HOUSE_COUNT = 'opencity.gis.house.count',
  GIS_FLAT_INDEX = 'opencity.gis.flat.index',
  GIS_FLAT_COUNT = 'opencity.gis.flat.count',
  GIS_DISTRICT_INDEX = 'opencity.gis.district.index',
  GIS_DISTRICT_COUNT = 'opencity.gis.district.count',
  USER_STREET_INDEX = 'opencity.user.street.index',
  USER_HOUSE_INDEX = 'opencity.user.house.index',
  USER_FLAT_INDEX = 'opencity.user.flat.index',
  DEVICE_METERING_INDEX = 'opencity.device.metering.index',
  DEVICE_METERING_REFRESH = 'opencity.device.metering.refresh',
  DEVICE_METERING_VALUE_INDEX = 'opencity.device.metering.value.index',
  DEVICE_METERING_VALUE_CREATE = 'opencity.device.metering.value.create',
  FILE_CREATE = 'opencity.file.create',
  FILE_INDEX = 'opencity.file.index',
  REFERENCE_INTERRUPT_REASON_INDEX = 'opencity.reference.interrupt.reason.index',
  REFERENCE_INTERRUPT_SENDER_INDEX = 'opencity.reference.interrupt.sender.index',
  REFERENCE_INTERRUPT_TYPE_INDEX = 'opencity.reference.interrupt.type.index',
  REFERENCE_CLASSIFIER_CATEGORY_INDEX = 'opencity.reference.classifier.category.index',
  REFERENCE_CLASSIFIER_TRACKER_INDEX = 'opencity.reference.classifier.tracker.index',
  REFERENCE_CLASSIFIER_TYPE_INDEX = 'opencity.reference.classifier.type.index',
  REFERENCE_CLASSIFIER_TAG_INDEX = 'opencity.reference.classifier.tag.index',
  FEEDBACK_CREATE = 'opencity.feedback.create',

  AUTH_OPERATION_ALLOWED = '_auth.operation.allowed',
  REPORT_INTERRUPT_CREATE = 'opencity.report.interrupt.create',
  REPORT_INTERRUPT_EXTENDED_CREATE = 'opencity.report.interrupt.extended.create',
  REPORT_INTERRUPT_SENDER_CREATE = 'opencity.report.interrupt.sender.create',
  REPORT_INTERRUPT_CONTROL_CREATE = 'opencity.report.interrupt.control.create',
  REPORT_ISSUE_OPERATOR_CREATE = 'opencity.report.issue.operator.create',
  REPORT_COUNTER_CREATE = 'opencity.report.counter.create',
  REPORT_ISSUE_CHECKERBOARD = 'opencity.report.issue.checkerboard',
  REPORT_ISSUE_FOOTCLOTH = 'opencity.report.issue.footcloth',
  REPORT_ISSUE_CREATE = 'opencity.report.issue.create',
  REPORT_INDEX = 'opencity.report.index',

  AUTH_PROVIDER_GET_URL = 'opencity.auth.provider.get_url',
  AUTH_PROVIDER_LOGIN = 'opencity.auth.provider.login',
  ISSUE_CHANGE_PROVIDER = 'opencity.issue.change_provider',
  ISSUE_CHANGE_CLASSIFIER = 'opencity.issue.change_classifier',
  CACHE_ISSUE_COUNT = 'opencity.cache.issue.count',
  USER_CODE_GENERATE = 'opencity.user.code.generate',
  USER_CODE_CONFIRM = 'opencity.user.code.confirm',
  USER_CODE_VALIDATE = 'opencity.user.code.validate',
  AUTH_LOGIN_EXIST = 'opencity.auth.login.exist',

  USER_NOTIFICATION_CREATE = 'opencity.user.notification.create',
  USER_NOTIFICATION_DELETE = 'opencity.user.notification.delete',
  USER_NOTIFICATION_UNSUBSCRIBE = 'opencity.user.notification.unsubscribe',
  USER_CODE_GET_NEXT_TIME = 'opencity.user.code.get_next_time',

  TASK_COMMENT_CREATE = 'opencity.task.comment.create',

  ISSUE_VIEW = 'opencity.issue.view',

  ISSUE_NOTICE = 'opencity.issue.notice',

  ISSUE_NEW_DONE_UPDATE_1 = 'workflow.issue.new.done.update_1',
  ISSUE_NEW_DONE_UPDATE_10 = 'workflow.issue.new.done.update_10',
  ISSUE_NEW_DONE_UPDATE_11 = 'workflow.issue.new.done.update_11',
  ISSUE_NEW_DONE_UPDATE_12 = 'workflow.issue.new.done.update_12',

  ISSUE_NEW_TO_PROVIDER_UPDATE_1 = 'workflow.issue.new.to_provider.update_1',
  ISSUE_NEW_TO_PROVIDER_UPDATE_10 = 'workflow.issue.new.to_provider.update_10',
  ISSUE_NEW_TO_PROVIDER_UPDATE_11 = 'workflow.issue.new.to_provider.update_11',
  ISSUE_NEW_TO_PROVIDER_UPDATE_12 = 'workflow.issue.new.to_provider.update_12',

  ISSUE_NEW_DELETED_UPDATE_1 = 'workflow.issue.new.deleted.update_1',
  ISSUE_NEW_DELETED_UPDATE_11 = 'workflow.issue.new.deleted.update_11',

  ISSUE_NEW_IN_WORK_UPDATE_1 = 'workflow.issue.new.in_work.update_1',
  ISSUE_NEW_IN_WORK_UPDATE_11 = 'workflow.issue.new.in_work.update_11',

  ISSUE_NEW_DELAYED_UPDATE_1 = 'workflow.issue.new.delayed.update_1',
  ISSUE_NEW_DELAYED_UPDATE_10 = 'workflow.issue.new.delayed.update_10',
  ISSUE_NEW_DELAYED_UPDATE_11 = 'workflow.issue.new.delayed.update_11',
  ISSUE_NEW_DELAYED_UPDATE_12 = 'workflow.issue.new.delayed.update_12',

  ISSUE_DONE_OK_UPDATE_1 = 'workflow.issue.done.ok.update_1',

  ISSUE_DONE_NEW_UPDATE_1 = 'workflow.issue.done.new.update_1',
  ISSUE_DONE_NEW_UPDATE_11 = 'workflow.issue.done.new.update_11',

  ISSUE_DONE_DELETED_UPDATE_1 = 'workflow.issue.done.deleted.update_1',
  ISSUE_DONE_DELETED_UPDATE_11 = 'workflow.issue.done.deleted.update_11',

  ISSUE_DONE_TO_PROVIDER_UPDATE_1 = 'workflow.issue.done.to_provider.update_1',

  ISSUE_DONE_CLOSED_UPDATE_1 = 'workflow.issue.done.closed.update_1',
  ISSUE_DONE_CLOSED_UPDATE_10 = 'workflow.issue.done.closed.update_10',
  ISSUE_DONE_CLOSED_UPDATE_11 = 'workflow.issue.done.closed.update_11',
  ISSUE_DONE_CLOSED_UPDATE_12 = 'workflow.issue.done.closed.update_12',

  ISSUE_IN_WORK_DELETED_UPDATE_1 = 'workflow.issue.in_work.deleted.update_1',

  ISSUE_IN_WORK_DONE_UPDATE_1 = 'workflow.issue.in_work.done.update_1',
  ISSUE_IN_WORK_DONE_UPDATE_11 = 'workflow.issue.in_work.done.update_11',

  ISSUE_TO_PROVIDER_TO_PROVIDER_UPDATE_1 = 'workflow.issue.to_provider.to_provider.update_1',
  ISSUE_TO_PROVIDER_TO_PROVIDER_UPDATE_11 = 'workflow.issue.to_provider.to_provider.update_11',

  ISSUE_UNCONFIRMED_TO_PROVIDER_UPDATE_1 = 'workflow.issue.unconfirmed.to_provider.update_1',
  ISSUE_UNCONFIRMED_TO_PROVIDER_UPDATE_11 = 'workflow.issue.unconfirmed.to_provider.update_11',

  ISSUE_TO_PROVIDER_DELAYED_UPDATE_1 = 'workflow.issue.to_provider.delayed.update_1',
  ISSUE_TO_PROVIDER_DELAYED_UPDATE_11 = 'workflow.issue.to_provider.delayed.update_11',

  ISSUE_TO_PROVIDER_DELETED_1 = 'workflow.issue.to_provider.deleted.update_1',
  ISSUE_TO_PROVIDER_DELETED_11 = 'workflow.issue.to_provider.deleted.update_11',

  ISSUE_OK_CLOSED_UPDATE_1 = 'workflow.issue.ok.closed.update_1',

  ISSUE_OK_UNCONFIRMED_UPDATE_1 = 'workflow.issue.ok.unconfirmed.update_1',

  ISSUE_UNCONFIRMED_CLOSED_UPDATE_1 = 'workflow.issue.unconfirmed.closed.update_1',

  ISSUE_UNCONFIRMED_TO_EXECUTE_UPDATE_1 = 'workflow.issue.unconfirmed.to_execute.update_1',

  ISSUE_UNCONFIRMED_DONE_UPDATE_1 = 'workflow.issue.unconfirmed.done.update_1',
  ISSUE_UNCONFIRMED_DONE_UPDATE_10 = 'workflow.issue.unconfirmed.done.update_10',
  ISSUE_UNCONFIRMED_DONE_UPDATE_11 = 'workflow.issue.unconfirmed.done.update_11',
  ISSUE_UNCONFIRMED_DONE_UPDATE_12 = 'workflow.issue.unconfirmed.done.update_12',

  ISSUE_UNCONFIRMED_DELAYED_UPDATE_1 = 'workflow.issue.unconfirmed.delayed.update_1',
  ISSUE_UNCONFIRMED_DELAYED_UPDATE_10 = 'workflow.issue.unconfirmed.delayed.update_10',
  ISSUE_UNCONFIRMED_DELAYED_UPDATE_11 = 'workflow.issue.unconfirmed.delayed.update_11',
  ISSUE_UNCONFIRMED_DELAYED_UPDATE_12 = 'workflow.issue.unconfirmed.delayed.update_12',

  ISSUE_TO_EXECUTE_TO_PROVIDER_UPDATE_1 = 'workflow.issue.to_execute.to_provider.update_1',

  ISSUE_TO_EXECUTE_DONE_UPDATE_1 = 'workflow.issue.to_execute.done.update_1',

  ISSUE_TO_EXECUTE_DELETED_UPDATE_1 = 'workflow.issue.to_execute.deleted.update_1',

  ISSUE_TO_EXECUTE_DELAYED_UPDATE_1 = 'workflow.issue.to_execute.delayed.update_1',

  ISSUE_DELAYED_NEW_UPDATE_1 = 'workflow.issue.delayed.new.update_1',
  ISSUE_DELAYED_NEW_UPDATE_10 = 'workflow.issue.delayed.new.update_10',
  ISSUE_DELAYED_NEW_UPDATE_11 = 'workflow.issue.delayed.new.update_11',
  ISSUE_DELAYED_NEW_UPDATE_12 = 'workflow.issue.delayed.new.update_12',

  ISSUE_DELAYED_TO_PROVIDER_UPDATE_1 = 'workflow.issue.delayed.to_provider.update_1',
  ISSUE_DELAYED_TO_PROVIDER_UPDATE_11 = 'workflow.issue.delayed.to_provider.update_11',

  ISSUE_DELAYED_DONE_UPDATE_1 = 'workflow.issue.delayed.done.update_1',
  ISSUE_DELAYED_DONE_UPDATE_11 = 'workflow.issue.delayed.done.update_11',

  ISSUE_DELAYED_TO_EXECUTE_UPDATE_1 = 'workflow.issue.delayed.to_execute.update_1',

  ISSUE_DELETED_NEW_UPDATE_1 = 'workflow.issue.deleted.new.update_1',
  ISSUE_DELETED_NEW_UPDATE_11 = 'workflow.issue.deleted.new.update_11',

  ISSUE_DONE_UNCONFIRMED_UPDATE_10 = 'workflow.issue.done.unconfirmed.update_10',
  ISSUE_DONE_UNCONFIRMED_UPDATE_11 = 'workflow.issue.done.unconfirmed.update_11',
  ISSUE_DONE_UNCONFIRMED_UPDATE_12 = 'workflow.issue.done.unconfirmed.update_12',

  ISSUE_DONE_DELAYED_UPDATE_11 = 'workflow.issue.done.delayed.update_11',

  ISSUE_DELAYED_UNCONFIRMED_UPDATE_1 = 'workflow.issue.delayed.unconfirmed.update_1',
  ISSUE_DELAYED_UNCONFIRMED_UPDATE_10 = 'workflow.issue.delayed.unconfirmed.update_10',
  ISSUE_DELAYED_UNCONFIRMED_UPDATE_12 = 'workflow.issue.delayed.unconfirmed.update_12',

  TASK_INDEX = 'opencity.task.index',
  TASK_STATE_INDEX = 'opencity.task.state.index',
  USER_PREFERENCE_UPDATE = 'opencity.user.preference.update',
  USER_PREFERENCE_INDEX = 'opencity.user.preference.index',
  CAPTCHA_CHECK = 'opencity.captcha.check',
}

export default OpencityMethods;
