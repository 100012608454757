import { observable, computed, action, toJS } from 'mobx';

import Services from '@services/index';
import Store from './Store';
import { RadioReceipt } from '@core/constants/radioButtons';

interface SuccessModal {
  isOpen: boolean;
  case: RadioReceipt;
}

class ChangeEmailStore extends Store {
  @observable private _isModalOpen: boolean;
  @observable private _isGeneralTabOpen: boolean;
  @observable private _successModal: SuccessModal;

  public constructor(services: Services) {
    super(services);

    this._isModalOpen = false;
    this._isGeneralTabOpen = false;
    this._successModal = {
      isOpen: false,
      case: undefined,
    };
  }

  @action public setModalState = (isModalOpen: boolean): void => {
    this._isModalOpen = isModalOpen;
  };

  @action public setGeneralTabState = (isGeneralTabOpen: boolean): void => {
    this._isGeneralTabOpen = isGeneralTabOpen;
  };

  @action public setSuccessModal = (value: SuccessModal): void => {
    this._successModal = value;
  };

  @computed public get modalState(): boolean {
    return toJS(this._isModalOpen);
  }

  @computed public get isGeneralTabOpen(): boolean {
    return toJS(this._isGeneralTabOpen);
  }

  @computed public get successModal(): SuccessModal {
    return toJS(this._successModal);
  }
}
export default ChangeEmailStore;
