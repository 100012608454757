import { AddFilter, RemoveFilter, ClearFilter } from '@stores/interfaces/Filter';
import { Load, Create, Update, Get } from '@stores/interfaces/Entity';

export interface ProfileInterface {
  id: number;
  email?: string | null;
  lastname?: string | null;
  name?: string | null;
  patronymic?: string | null;
  phone?: string | null;
  type?: ProfileTypes | null;
  personalAccountId?: number | null;
}

export interface CreateProfileInterface {
  email?: string | null;
  lastname?: string | null;
  name?: string | null;
  patronymic?: string | null;
  phone?: string | null;
}

export interface CreateFlatAccountOwnerInterface {
  profileId: number;
  personalAccountId: number;
}
export class Profile {
  public id: number | null;
  public email?: string | null;
  public lastName?: string | null;
  public name?: string | null;
  public patronymic?: string | null;
  public phone?: string | null;
  public type?: ProfileTypes | null;
  public personalAccountId?: number | null;

  public constructor(value: ProfileInterface) {
    this.id = value.id;
    this.email = value.email;
    this.lastName = value.lastname;
    this.name = value.name;
    this.patronymic = value.patronymic;
    this.phone =
      typeof value.phone === 'string' ? value.phone?.replace(/^(8|7)/g, '+7') : value.phone;
    this.type = value.type;
    this.personalAccountId = value.personalAccountId;
  }
}

export type ProfileTypes = 'user' | 'owner' | 'payer' | 'booker' | 'director' | 'engineer';

export enum ProfileKeys {
  PERSONAL_ACCOUNT_ID = 'personalAccountId',
  OWNER_PERSONAL_ACCOUNT_ID = 'ownerPersonalAccountId',
  TYPE = 'type',
}

export interface ProfileFilterProps {
  [ProfileKeys.PERSONAL_ACCOUNT_ID]?: number;
  [ProfileKeys.OWNER_PERSONAL_ACCOUNT_ID]?: number;

  [ProfileKeys.TYPE]?: ProfileTypes;
}

export type AddProfileFilter = AddFilter<ProfileFilterProps>;

export type RemoveProfileFilter = RemoveFilter<ProfileKeys>;

export type ClearProfileFilter = ClearFilter;

export type LoadProfile = Load<
  { filter?: ProfileFilterProps; limit?: number; offset?: number },
  Profile[]
>;

export type GetProfile = Get<Profile, ProfileFilterProps>;

export type CreateProfile = Create<CreateProfileInterface, Promise<Profile | null>>;
export type CreateFlatAccountOwner = Create<CreateFlatAccountOwnerInterface, Promise<boolean>>;

export type UpdateProfile = Update<
  { payload?: Partial<Profile>; entity?: Profile },
  Promise<Profile | undefined>
>;
