import { AxiosResponse } from 'axios';
import { observable, computed, action, toJS } from 'mobx';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import { User, DeleteUser } from '@core/entities/Opencity/User';
import Store from './Store';
import { Loading, endLoading } from '@stores/interfaces/Loading';
import { Entity } from '@stores/interfaces/Entity';

type UserDeleteResponse = JrpcResponse<boolean>;

class UserStore extends Store implements Loading, Entity<User, {}> {
  @observable private _users: User[];
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services) {
    super(services);

    this._loading = false;
    this._users = [];
  }

  @action public delete: DeleteUser = async ({ filter }) => {
    this._loading = true;

    let isDeleted = false;

    await this._services.opencity.requests
      .userDelete({ params: { filter } })
      .then(({ data: { result } }: AxiosResponse<UserDeleteResponse>): void => {
        if (typeof result === 'boolean') {
          isDeleted = result;
        }
      })
      .finally(this._endLoading);

    return isDeleted;
  };

  @computed public get list(): User[] {
    return toJS<User[]>(this._users);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default UserStore;
