enum BillingMethods {
  USER_ACCOUNT_INDEX = 'billing.user.account.index',
  USER_ACCOUNT_CREATE = 'billing.user.account.create',
  USER_ACCOUNT_UPDATE = 'billing.user.account.update',
  ACCOUNT_INDEX = 'billing.account.index',
  ACCOUNT_EXISTS = 'billing.account.exists',
  COUNTER_VALUE_CREATE = 'billing.counter.value.create',
  COUNTER_INDEX = 'billing.counter.index',
  MERCHANT_INDEX = 'billing.merchant.index',
  MERCHANT_GROUP_INDEX = 'billing.merchant.group.index',
  SERVICE_INDEX = 'billing.service.index',
  QR_CODE_CREATE = 'billing.qr.code.create',
  QR_CODE_SEND = 'billing.qr.code.email',
  PAYMENT_RECEIPT_INDEX = 'billing.payment.receipt.index',
  PAYMENT_INDEX = 'billing.payment.index',
  PAYMENT_GET_LINK = 'billing.payment.get_link',
  ACCRUAL_INDEX = 'billing.accrual.index',
  ORGANIZATION_INDEX = 'billing.organization.index',
}

export default BillingMethods;
