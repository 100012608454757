import { FilterOperators } from '@stores/interfaces/Filter';

export interface TaskStateInterface {
  id: number;
  taskId?: number;
  userId?: number;
  state?: string;
  comment?: string;
  timestamp?: number;
  userRole?: string;
  organizationInfo: string;
  orderId?: number;
  userFullName?: string;
}

export class TaskState {
  public id: number;
  public taskId?: number;
  public userId?: number;
  public state?: string;
  public comment?: string;
  public timestamp?: number;
  public userRole?: string;
  public organizationInfo: string;
  public orderId?: number;
  public userFullName?: string;

  constructor(value: TaskStateInterface) {
    this.id = value.id;
    this.taskId = value.taskId;
    this.userId = value.userId;
    this.state = value.state;
    this.comment = value.comment;
    this.timestamp = value.timestamp;
    this.userRole = value.userRole;
    this.organizationInfo = value.organizationInfo;
    this.orderId = value.orderId;
    this.userFullName = value.userFullName;
  }
}

export enum TaskStateKeys {
  ID = 'id',
  TASK_ID = 'taskId',
  TASK_IDS = 'taskIds',

  TIMESTAMP = 'timestamp',
  ORDER_ID = 'orderId',
  ORDER_ORGANIZATION_ID = 'orderOrganizationId',
  ORDER_USER_ID = 'orderUserId',
  ORDER_PERSONAL_ACCOUNT_ID = 'orderPersonalAccountId',
  ORDER_FLAT_ID = 'orderFlatId',
  ORDER_DISTRICT_ID = 'orderDistrictId',
  ORDER_TRACKER_ID = 'orderTrackerId',
  ORDER_CONTROL_ID = 'orderControlId',
  ORDER_PROVIDER_ID = 'orderProviderId',
  ROOT_ORGANIZATION_id = 'rootOrganizationId',
  PARENT_TASK_ID = 'parentTaskId',
}

export interface TaskStateFilterProps {
  [TaskStateKeys.ID]?: FilterOperators<number>;
  [TaskStateKeys.TASK_ID]?: FilterOperators<number>;
  [TaskStateKeys.PARENT_TASK_ID]?: FilterOperators<number>;
  [TaskStateKeys.TIMESTAMP]?: FilterOperators<number>;
  [TaskStateKeys.ORDER_ID]?: FilterOperators<number>;
  [TaskStateKeys.ORDER_ORGANIZATION_ID]?: FilterOperators<number>;
  [TaskStateKeys.ORDER_USER_ID]?: FilterOperators<number>;
  [TaskStateKeys.ORDER_PERSONAL_ACCOUNT_ID]?: FilterOperators<number>;
  [TaskStateKeys.ORDER_FLAT_ID]?: FilterOperators<number>;
  [TaskStateKeys.ORDER_DISTRICT_ID]?: FilterOperators<number>;
  [TaskStateKeys.ORDER_TRACKER_ID]?: FilterOperators<number>;
  [TaskStateKeys.ORDER_CONTROL_ID]?: FilterOperators<number>;
  [TaskStateKeys.ORDER_PROVIDER_ID]?: FilterOperators<number>;
  [TaskStateKeys.ROOT_ORGANIZATION_id]?: FilterOperators<number>;
}
