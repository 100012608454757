import { action } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@core/services';
import Store from './Store';
import { Task, LoadTasks, TaskInterface } from '@core/entities/Opencity/Task';
import { JrpcResponse } from '@httpClient/jrpc';

type TaskIndexResponse = JrpcResponse<{ items: TaskInterface[]; total: number }>;

class TaskStore extends Store {
  constructor(services: Services) {
    super(services);
  }

  @action public loadTasks: LoadTasks = async (params): Promise<Task[]> => {
    let tasks: Task[] = [];

    if (params) {
      await this._services.opencity.requests
        .taskIndex({ params })
        .then(({ data: { result } }: AxiosResponse<TaskIndexResponse>) => {
          if (result) {
            tasks = result.items;
          }
        });
    }

    return tasks;
  };
}

export default TaskStore;
