import { observable, action, computed } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import AuthenticationStore from './AuthenticationStore';
import SnackbarStore from './SnackbarStore';
import { JrpcResponse, JrpcResponseError } from '@httpClient/jrpc';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';

type UserCreateResponse = JrpcResponse<
  boolean,
  {
    email?: string;
    phone?: string;
    password?: string;
    code?: string;
    errorCode?: number;
  }
>;

// type UserGenerateCode = JrpcResponse<boolean, { phone: string }>;

type UserGetCodeNextTime = JrpcResponse<{ time: number; timeLeft: number }, { phone: string }>;
type CodeGenerateResponse = JrpcResponse<
  boolean,
  {
    phone?: string;
  }
>;

type CodeConfirmResponse = JrpcResponse<
  boolean,
  {
    code?: string;
  }
>;

type UserCodeValidateResponse = JrpcResponse<boolean, { code?: string }>;
type CaptchaCheckResult = JrpcResponse<boolean>;

class SignStore extends Store implements Loading {
  @observable public signUpCompleted: boolean;
  @observable private _loading: boolean;

  private readonly _authenticationStore: AuthenticationStore;
  private readonly _snackBarStore: SnackbarStore;
  @action private _endLoading = endLoading(200).bind(this);

  public constructor(
    services: Services,
    authenticationStore: AuthenticationStore,
    snackBarStore: SnackbarStore,
  ) {
    super(services);

    this._authenticationStore = authenticationStore;
    this._snackBarStore = snackBarStore;
    this.signUpCompleted = false;
    this._loading = false;
  }

  @action public userGenerateCode = async (
    address: string,
    transport: 'sms' | 'email',
  ): Promise<string | null> => {
    let resultError = null;
    this._loading = true;

    await this._services.opencity.requests
      .userCodeGenerate({
        params: { address, transport },
      })
      .then(({ data: { error } }: AxiosResponse<CodeGenerateResponse>) => {
        if (error) {
          if (error.data?.phone) {
            resultError = error.data.phone;
          } else {
            this._snackBarStore.setMessage('Ошибка генерации кода', 'error');
            // resultError = 'Ошибка генерации кода';
          }
        }
      })
      .finally(this._endLoading);

    return resultError;
  };

  @action public captchaCheck = async (
    params: {
      response: string;
    },
    withMessage?: boolean,
  ): Promise<boolean> => {
    let checkResult = false;
    await this._services.opencity.requests
      .captchaCheck({ params })
      .then(({ data: { result } }: AxiosResponse<CaptchaCheckResult>) => {
        if (result) {
          checkResult = result;
        } else {
          if (withMessage)
            this._snackBarStore.setMessage(
              'В целях безопасности пользователей мы вынуждены отклонить ваш запрос. Попробуйте повторить запрос позднее',
              'error',
            );
        }
      });
    return checkResult;
  };

  @action public getCodeNextTime = async (
    phone: string,
  ): Promise<{ error?: string; timeLeft?: number }> => {
    const nextTimeResult: { error?: string; timeLeft?: number } = {};
    this._loading = true;

    await this._services.opencity.requests
      .userCodeGetNextTime({ params: { phone } })
      .then(({ data: { result, error } }: AxiosResponse<UserGetCodeNextTime>) => {
        if (result) {
          nextTimeResult.timeLeft = result.timeLeft;
        }
        if (error) {
          this._snackBarStore.setMessage('Ошибка запроса времени кода', 'error');
        }
      })
      .finally(this._endLoading);

    return nextTimeResult;
  };

  @action public userCodeConfirm = async (
    address: string,
    code: string,
  ): Promise<string | null> => {
    let resultError = null;
    this._loading = true;

    await this._services.opencity.requests
      .userCodeConfirm({
        params: { address, code },
      })
      .then(({ data: { error } }: AxiosResponse<CodeConfirmResponse>) => {
        if (error) {
          if (error.data && error.data.code) {
            resultError = error.data.code;
          } else {
            this._snackBarStore.setMessage('Ошибка подтверждения кода', 'error');
          }
        }
      });

    return resultError;
  };

  @action public userCodeValidate = async (
    address: string,
    transport: 'phone' | 'email',
    code: string,
  ): Promise<boolean | string> => {
    let validateResult: string | boolean = false;
    this._loading = true;

    await this._services.opencity.requests
      .userCodeValudate({
        params: { address, code, transport },
      })
      .then(({ data: { result, error } }: AxiosResponse<UserCodeValidateResponse>) => {
        if (result) validateResult = result;
        if (error?.data?.code) {
          validateResult = error.data.code;
        }
      })
      .finally(this._endLoading);

    return validateResult;
  };

  @action public authLoginExist = async (login: string): Promise<boolean> => {
    let existResult = false;
    this._loading = true;

    await this._services.opencity.requests
      .authLoginExist({ params: { login } })
      .then(({ data: { result } }: AxiosResponse<UserCodeValidateResponse>) => {
        if (result) existResult = result;
      })
      .finally(this._endLoading);

    return existResult;
  };

  @action public signUp = async (email: string): Promise<string | null> => {
    let resultError = null;
    this._loading = true;

    await this._services.opencity.requests
      .userCreate({
        params: { data: { email } },
      })
      .then(({ data: { result, error } }: AxiosResponse<UserCreateResponse>) => {
        if (result) {
          this.signUpCompleted = true;
        }

        if (error) {
          if (error.data) {
            if (error.data.email) {
              if (error.data.email && error.data.email[0]) {
                resultError = error.data.email[0];
              }
            }
          }
        }
      })
      .finally(this._endLoading);

    return resultError;
  };

  @action public signUpByPhone = async (
    phone: string,
    password: string,
    code: string,
  ): Promise<{ phone?: string; code?: string; errorCode?: number }> => {
    const resultError: { phone?: string; code?: string; errorCode?: number } = {};
    this._loading = true;

    await this._services.opencity.requests
      .userCreate({
        params: { data: { phone, password, code } },
      })
      .then(({ data: { error } }: AxiosResponse<UserCreateResponse>) => {
        if (error) {
          if (error.data) {
            if (error.data.password) {
              this._snackBarStore.setMessage(error.data.password[0], 'error')
            }
            if (error.code) {
              resultError.errorCode = Number(error.code);
            }

            if (error.data.code) {
              resultError.code = error.data.code;
            }

            if (error.data.phone) {
              resultError.phone = error.data.phone;
            }
          } else {
            this._snackBarStore.setMessage('Ошибка регистрации', 'error');
          }
        }
      })
      .finally(this._endLoading);

    return resultError;
  };

  @action public signIn = (username: string, password: string): Promise<JrpcResponseError<{}>> => {
    return this._authenticationStore.signIn(username, password);
  };

  @action public cleanSignUpValues = (): void => {
    this.signUpCompleted = false;
    this._loading = false;
  };

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default SignStore;
