import { AxiosResponse } from 'axios';
import { observable, computed, action, toJS } from 'mobx';
import { uniq } from 'ramda';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import { TransitionInterface, Transition } from '@entities/Workflow/Transition';
import { Order } from '@core/entities/Opencity/Order';
import { Task } from '@core/entities/Opencity/Task';
import city from '@core/constants/project';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import OwnProfileStore from '@stores/OwnProfileStore';

type TransitionIndex = JrpcResponse<{ items: TransitionInterface[] }>;

interface TransitionStoreRelations {
  ownProfileStore: OwnProfileStore;
}

class WorkflowStore extends Store<TransitionStoreRelations> implements Loading {
  @observable private _loading: boolean;
  @observable private _transitions: Transition[];

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services, relations: TransitionStoreRelations) {
    super(services, relations);

    this._transitions = [];
    this._loading = false;
  }

  @action public load = async (
    order: Order,
    stateName?: string | null,
    trackerId?: number | null,
  ): Promise<Transition[]> => {
    this._loading = true;
    let transitions: Transition[] = [];

    await this._services.workflow.requests
      .transitionIndex({
        params: {
          filter: {
            state_from: {
              aeq: stateName,
            },
            schema_name: {
              aeq: `${city}_${trackerId}`,
            },
          },
          context: {
            userId: this._relations.ownProfileStore.user?.id,
          },
          entity: {
            id: order.id,
            name: 'issue',
            parentId: order.parentId,
            problemId: order.problemId,
            state_name: stateName,
          },
          // entity: order,
          // schema_name: `${city}_${order?.trackerId}`,
          // state_name: stateName,
        },
      })
      .then(({ data: { result } }: AxiosResponse<TransitionIndex>) => {
        if (result && Array.isArray(result.items)) {
          transitions = uniq(result.items).map(value => new Transition(value));
          this._transitions = transitions;
        }
      })
      .finally(this._endLoading);

    return transitions;
  };

  @action public get = async (
    task: Task,
    stateName?: string | null,
    trackerId?: number | null,
  ): Promise<Transition[]> => {
    let transitions: Transition[] = [];

    await this._services.workflow.requests
      .transitionIndex({
        params: {
          filter: {
            state_from: {
              aeq: stateName,
            },
            schema_name: {
              aeq: `${city}_${trackerId}`,
            },
          },
          context: {
            userId: this._relations.ownProfileStore.user?.id,
          },
          entity: {
            id: task.id,
            name: 'issue',
            parentId: task.parentId,
            problemId: task.problemId,
            state_name: stateName,
          },
          // entity: order,
          // schema_name: `${city}_${order?.trackerId}`,
          // state_name: stateName,
        },
      })
      .then(({ data: { result } }: AxiosResponse<TransitionIndex>) => {
        if (result && Array.isArray(result.items)) {
          transitions = uniq(result.items).map(value => new Transition(value));
        }
      });

    return transitions;
  };

  @action public cleanUp = (): void => {
    this._transitions = [];
    this._loading = false;
  };

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get list(): Transition[] {
    return toJS(this._transitions);
  }
}

export default WorkflowStore;
