import Store from './Store';
import { observable, action } from 'mobx';
import city from '@core/constants/project';
import Services from '@core/services';
import { MINIO_END_POINT } from '@core/constants/minio';

import { v4 as uuidv4 } from 'uuid';

export class AvatarStore extends Store {
  @observable public _avatar: string;

  public constructor(services: Services) {
    super(services);

    this._avatar = '';
  }

  private createArrayBuffer(url: string): ArrayBufferLike {
    const base64str = url.split('base64,')[1];
    const binary_string = atob(base64str);

    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private getFileSize(url: string): number {
    const base64str = url.split('base64,')[1];
    const decoded = atob(base64str);

    return decoded.length;
  }

  private getMimeType(url: string): string {
    const mimeType = url.split('data:')[1].split(';base64')[0];
    return mimeType;
  }

  private getFileType(url: string): string {
    const fileType = this.getMimeType(url).split('/')[1];
    return fileType;
  }

  @action public createOrUpdateAvatar = async (url: string, profileId: number): Promise<void> => {
    const fileName = uuidv4();
    const fileType = this.getFileType(url);

    const response = await fetch(
      `${MINIO_END_POINT}/${city}/notsecured/avatar/${fileName}.${fileType}`,
      {
        method: 'PUT',
        body: this.createArrayBuffer(url),
        headers: {
          'Content-Type': this.getMimeType(url),
          'Content-Length': String(this.getFileSize(url)),
        },
      },
    );

    if (response.status === 200) {
      this._services.opencity.requests
        .fileCreate({
          id: uuidv4(),
          params: {
            data: [
              {
                fileName,
                isSecure: false,
                mimeType: this.getMimeType(url),
                originalFileName: fileName,
                size: this.getFileSize(url),
                url: `/${city}/notsecured/avatar/${fileName}.${fileType}`,
              },
            ],
          },
        })
        .then(response => {
          const { id } = response.data.result[0];
          if (this._avatar.length > 0) {
            this._services.opencity.requests
              .avatarUpdate({
                id: uuidv4(),
                params: {
                  filter: {
                    profileId: profileId,
                  },
                  data: {
                    fileId: id,
                  },
                },
              })
              .then(response => {
                const { fileUrl } = response.data.result;
                this._avatar = fileUrl;
              });
          } else {
            this._services.opencity.requests
              .avatarCreate({
                id: uuidv4(),
                params: {
                  data: {
                    profileId: profileId,
                    fileId: id,
                  },
                },
              })
              .then(response => {
                const { fileUrl } = response.data.result;
                this._avatar = fileUrl;
              });
          }
        });
    }
  };

  @action public deleteAvatar = async (profileId: number): Promise<void> => {
    await this._services.opencity.requests
      .avatarDelete({
        id: uuidv4(),
        params: {
          filter: {
            profileId: profileId,
          },
        },
      })
      .then(() => {
        this._avatar = '';
      });
  };

  @action public loadAvatar = async (profileId: number): Promise<void> => {
    if (this._avatar === '') {
      await this._services.opencity.requests
        .avatarIndex({
          id: uuidv4(),
          params: {
            filter: {
              profileId: profileId,
            },
          },
        })
        .then(response => {
          if (response.data.result && response.data.result.items.length > 0) {
            const { fileUrl } = response.data.result.items[0];
            if (!fileUrl) {
              this._avatar = '';
            } else {
              this._avatar = fileUrl;
            }
          }
        });
    }
  };
}
