import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@services/index';
import {
  LoadMerchant,
  MerchantFilterProps,
  MerchantKeys,
  Merchant,
  MerchantInterface,
} from '@entities/Billing/Merchant';
import Store from './Store';
import { Loading, endLoading } from '@stores/interfaces/Loading';
import { Entity } from '@stores/interfaces/Entity';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';

type MerchantIndexResponse = JrpcResponse<{ items: MerchantInterface[]; total: number }>;

class MerchantStore extends Store
  implements
    Loading,
    Pagination,
    Filter<MerchantFilterProps, MerchantKeys>,
    Entity<Merchant, { filter?: MerchantFilterProps; limit?: number; offset?: number }> {
  @observable private _merchants: Merchant[];
  @observable private _filter: MerchantFilterProps;
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services) {
    super(services);

    this._merchants = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  }

  @action public load: LoadMerchant = async params => {
    let merchants: Merchant[] = [];

    this._loading = true;

    const filter = params?.filter || toJS(this._filter);
    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.billing.requests
      .merchantIndex({ params: { filter, limit, offset } })
      .then(({ data: { result } }: AxiosResponse<MerchantIndexResponse>) => {
        if (result?.items && Array.isArray(result.items)) {
          merchants = result.items.map(value => new Merchant(value));

          this._merchants = merchants;
        }

        if (result?.total && typeof result.total === 'number') {
          this._total = result.total;
        }
      });

    return merchants;
  };

  @action public setLimit: SetLimit = limit => {
    this._limit = limit;
  };

  @action public setOffset: SetOffset = offset => {
    this._offset = offset;
  };

  @action public cleanUp = (): void => {
    this._merchants = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  };

  @computed public get list(): Merchant[] {
    return toJS(this._merchants);
  }

  @computed public get filter(): MerchantFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default MerchantStore;
