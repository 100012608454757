import { AxiosRequestConfig } from 'axios';

import { HttpClient } from '@httpClient/index';
import Methods from './methods';
import ActionNames from './actionNames';

class BillingService extends HttpClient<ActionNames> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public constructor(request: any, options?: AxiosRequestConfig) {
    super(request, options);

    this.init();
  }

  private init = (): void => {
    this.requests[ActionNames.USER_ACCOUNT_INDEX] = this.request({
      method: Methods.USER_ACCOUNT_INDEX,
    });
    this.requests[ActionNames.USER_ACCOUNT_CREATE] = this.request({
      method: Methods.USER_ACCOUNT_CREATE,
    });
    this.requests[ActionNames.USER_ACCOUNT_UPDATE] = this.request({
      method: Methods.USER_ACCOUNT_UPDATE,
    });
    this.requests[ActionNames.ACCOUNT_INDEX] = this.request({ method: Methods.ACCOUNT_INDEX });

    this.requests[ActionNames.ACCOUNT_EXISTS] = this.request({ method: Methods.ACCOUNT_EXISTS });

    this.requests[ActionNames.COUNTER_VALUE_CREATE] = this.request({
      method: Methods.COUNTER_VALUE_CREATE,
    });

    this.requests[ActionNames.COUNTER_INDEX] = this.request({ method: Methods.COUNTER_INDEX });

    this.requests[ActionNames.MERCHANT_INDEX] = this.request({ method: Methods.MERCHANT_INDEX });

    this.requests[ActionNames.MERCHANT_GROUP_INDEX] = this.request({
      method: Methods.MERCHANT_GROUP_INDEX,
    });

    this.requests[ActionNames.SERVICE_INDEX] = this.request({ method: Methods.SERVICE_INDEX });

    this.requests[ActionNames.QR_CODE_CREATE] = this.request({
      method: Methods.QR_CODE_CREATE,
    });

    this.requests[ActionNames.QR_CODE_SEND] = this.request({
      method: Methods.QR_CODE_SEND,
    });

    this.requests[ActionNames.PAYMENT_RECEIPT_INDEX] = this.request({
      method: Methods.PAYMENT_RECEIPT_INDEX,
    });

    this.requests[ActionNames.PAYMENT_INDEX] = this.request({ method: Methods.PAYMENT_INDEX });

    this.requests[ActionNames.PAYMENT_GET_LINK] = this.request({
      method: Methods.PAYMENT_GET_LINK,
    });

    this.requests[ActionNames.ACCRUAL_INDEX] = this.request({
      method: Methods.ACCRUAL_INDEX,
    });

    this.requests[ActionNames.PAYMENT_INDEX] = this.request({
      method: Methods.PAYMENT_INDEX,
    });
    this.requests[ActionNames.ORGANIZATION_INDEX] = this.request({
      method: Methods.ORGANIZATION_INDEX,
    });
  };
}

export default BillingService;
